import React, { useEffect } from 'react';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5locales_pt_BR from "@amcharts/amcharts5/locales/pt_BR";

interface ILineChart {
  data: any;
  options: any;
  slug?: any;
}

const LineChart: React.FC<ILineChart> = ({
  data,
  options,
  slug
}) => {
  useEffect(() => {
    am5.ready(function() {
      am5.array.each(am5.registry.rootElements, function(root) {
        if (root.dom.id === `chartdivline-${slug ?? "" }`) {
          root.dispose();
        }
      });
    });

    const root = am5.Root.new(`chartdivline-${slug ?? "" }`);


    root.locale = am5locales_pt_BR;

    root.setThemes([am5themes_Animated.new(root)]);

    const chart = root.container.children.push(am5xy.XYChart.new(root, {
      
    }));

    const xRenderer = am5xy.AxisRendererX.new(root, { minGridDistance: 0 });

    xRenderer.grid.template.setAll({
      location: 0,
    })

    xRenderer.labels.template.setAll({
      rotation: -65,
      centerY: am5.p50,
      centerX: am5.p100
    });

    let xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        paddingTop: 20,
        paddingBottom: 20,
        categoryField: "year",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      })
    );

    const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxPrecision: 0,
      paddingRight: 20,
      renderer: am5xy.AxisRendererY.new(root, {
       
      })
    }));

    const cursor = chart.set("cursor",
    am5xy.XYCursor.new(root, {
      behavior: "none",
    }));
    cursor.lineY.set("visible", true);

    cursor.setAll({
      xAxis: xAxis,
      yAxis: yAxis
    });

    data?.datasets?.forEach((dataset: any, index: any) => {

      let hh: any[] = []

      data?.labels?.forEach((label: any, index: any) => {
        hh.push({
          year: label,
          value: dataset?.data[index]
        })
      })

      xAxis.data.setAll(hh); 
      yAxis.data.setAll(hh); 

      const series = chart.series.push(am5xy.LineSeries.new(root, {
        name: dataset?.label,
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "year",
        fill: dataset?.backgroundColor,
        stroke: dataset?.borderColor
      }));

      let tooltip: any = am5.Tooltip.new(root, {
        getFillFromSprite: false,
        labelText: "{categoryX}: {valueY}",
      });
      
      tooltip.get("background").setAll({
        fill: am5.color(dataset?.backgroundColor),
        fillOpacity: 1
      });
      
      series.set("tooltip", tooltip);

      series.strokes.template.setAll({
        strokeWidth: 5,
      });

      series.bullets.push(function () {

        const circle = am5.Circle.new(root, {
          radius: 6,
          stroke: series.get('fill'),
          strokeWidth: 4,
          fill: root.interfaceColors.get("background"),
        });
        
        return am5.Bullet.new(root, {
          sprite: circle
        });

      });

      series.data.setAll(hh);

    })

    let legend = chart.children.push(am5.Legend.new(root, {
      y: am5.percent(98.8),
      centerX: am5.percent(50),
      x: am5.percent(50),
      scale: 0.75,
    }));
    legend.markers.template.setAll({
      width: 50,
      height: 20
    });
    legend.data.setAll(chart.series.values);
    return () => {
      root.dispose();
    };
  }, [data]);

  return (
    <div>
      <div id={`chartdivline-${slug ?? "" }`} style={{ width: "100%", height: "500px" }}></div>
    </div>
  );
}

export { LineChart };
