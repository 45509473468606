import { Box, Grid, Stack, Typography } from "@mui/material";
import * as React from "react";
import Balanca from "../assets/images/balanca.png";
import Mao from "../assets/images/aperto-mao.png";
import Police from "../assets/images/police-officer.png";
import Casa from "../assets/images/casa-familia.png";
import Predio from "../assets/images/predio.png";
import PrevencaoMulheres from "../assets/images/prevencao_mulheres.png";

interface IEvidenceSolutions {}

const EvidenceSolutions: React.FC<IEvidenceSolutions> = () => {
  return (
    <Stack gap={4}>
      <Typography
        sx={{
          font: "bold 25px / 33px IBM Plex Sans",
          color: "rgb(0, 78, 112)",
        }}
      >
        Confira as soluções de segurança pública baseadas em evidências clicando
        nos ícones abaixo
      </Typography>

      <Grid rowSpacing={2} columnSpacing={1} container>
        {[
          {
            id: 1,
            title: "Prevenção da Violência Infanto-Juvenil",
            background: "rgb(189, 147, 19)",
            icon: Casa,
            link: `https://plataformadeevidencias.iadb.org/pt-br/eixos/prevencao-da-violencia-infanto-juvenil`,
            texthover:
            
              "Fatores de risco e protetivos na área do desenvolvimento individual, familiar, escolar ou comunitário.",
          },
          {
            id: 6,
            title: "Prevenção da Violência contra as Mulheres",
            background: "rgb(16, 56, 92)",
            icon: PrevencaoMulheres,
            link: `https://plataformadeevidencias.iadb.org/index.php/pt-br/eixos/prevencao-da-violencia-contra-mulheres`,
            texthover:
              "Qualificação da gestão pública, das instituições e dos recursos humanos na área de segurança pública.",
          },
          {
            id: 2,
            title: "Segurança Urbana",
            background: "rgb(172, 82, 0)",
            icon: Predio,
            link: `https://plataformadeevidencias.iadb.org/pt-br/eixos/seguranca-urbana`,
            texthover:
              "Condições ambientais/situacionais que facilitam a ocorrência de crimes ou violências e/ou a incidência de determinados fatores criminogênicos.",
          },
          {
            id: 3,
            title: "Policiamento",
            background: "rgb(137, 31, 31)",
            icon: Police,
            link: `https://plataformadeevidencias.iadb.org/pt-br/eixos/policiamento`,
            texthover:
              "Temas associados à aplicação do poder de polícia e estratégias de policiamento baseadas em evidência.",
          },
          {
            id: 4,
            title: "Justiça Criminal",
            background: "rgb(16, 100, 86)",
            icon: Balanca,
            link: `https://plataformadeevidencias.iadb.org/index.php/pt-br/eixos/justica-criminal`,
            texthover:
              "Temas relacionados à política criminal e à eficiência do sistema de justiça e do sistema prisional.",
          },
          {
            id: 5,
            title: "Reinserção Social",
            background: "rgb(82, 10, 83)",
            icon: Mao,
            link: `https://plataformadeevidencias.iadb.org/index.php/pt-br/eixos/reinsercao-social`,
            texthover:
              "Programas voltados à redução da reincidência criminal, realizados em meio comunitário ou dentro do sistema prisional/internação.",
          },
        ]?.map((item: any, _: any) => (
          <Grid item xs={4} key={item?.id}>
            <Stack
              sx={{
                backgroundColor: item.background,
                height: "172px",
                boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px",
                borderRadius: "8px",
                cursor: "pointer",
              }}
              onClick={() => window.open(item.link, "_blank")}
              flex={1}
              gap={1}
              position="relative"
            >
              <Stack
                flex={1}
                justifyContent="center"
                alignItems="center"
                sx={{
                  borderRadius: "8px",
                  backgroundColor: item.background,
                  position: "absolute",
                  height: "100%",
                  width: "100%",
                  opacity: 0,
                  "&:hover": {
                    opacity: 1,
                  },
                  p: 6,
                }}
              >
                <Typography
                  sx={{
                    textAlign: "center",
                    font: "italic 14px IBM Plex Sans",
                    color: "rgb(255, 255, 255)",
                  }}
                >
                  {item?.texthover}
                </Typography>
              </Stack>
              <Stack
                flex={1}
                justifyContent="center"
                alignItems="center"
                gap={1}
                sx={{
                  "&:hover": {
                  },
                }}
              >
                <Box
                  sx={{
                    width: "70px",
                    height: "70px",
                    backgroundImage: `url(${item.icon})`,
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                  }}
                />
                <Typography
                  sx={{
                    textAlign: "center",
                    font: "bold 17px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "#ffffff",
                  }}
                >
                  {item?.title}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        ))}
      </Grid>
    </Stack>
  );
};

export { EvidenceSolutions };
