/* eslint-disable react-hooks/exhaustive-deps */
import * as React from 'react';
import debounce from "lodash.debounce";

interface ITextFieldDebounce {
  onCallback: any
  input: any
}

const TextFieldDebounce: React.FC<ITextFieldDebounce> = ({
  onCallback,
  input
}) => {
  const debounceFn = React.useCallback(debounce(onCallback, 1000), []);
  const onChange = React.useCallback((e: any) => debounceFn(e.target.value), [debounceFn]);
  return input({ onChange })
}

export { TextFieldDebounce }