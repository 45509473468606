import { Box, Grid, Stack, Typography } from "@mui/material";
import * as React from "react";
import noun_criminal_3678293 from "../../assets/images/noun_criminal_3678293.png";
import government from "../../assets/images/government.png";
import noun_risk_3338180 from "../../assets/images/noun_risk_3338180.png";
import { Card } from "./card";
import { PlayArrow } from "@mui/icons-material";
import { IndicatorPanel } from "../indicatorPanel";
import { IndicatorLegend } from "../indicatorLegend";

interface IGroupIndicatorPanels {
  is_metropolitan_city?: boolean
  type?: string;
  categories?: any[];
  locationId?: any;
}

const GroupIndicatorPanels: React.FC<IGroupIndicatorPanels> = ({
  categories = [],
  locationId,
  type,
  is_metropolitan_city
}) => {
  const [indicatorPanelCategory, setIndicatorPanelCategory] =
    React.useState<any>(categories[0]?.id);

  return (
    <Stack gap={4}>
      <Typography
        sx={{
          textAlign: {
            xs: "center",
            sm: "center",
            md: "left",
            lg: "left",
            xl: "left",
          },
          font: "normal normal bold 25px/34px Open Sans",
          letterSpacing: "0px",
          color: "#075E89",
          opacity: 1,
        }}
      >
        Acesse os painéis de indicadores clicando nos ícones abaixo
      </Typography>

      <Grid columnSpacing={8} rowSpacing={3} container>
        {categories?.map((item: any, _: any) => (
          <Grid key={item?.id} xs={12} sm={12} md={4} lg={4} xl={4} item>
            <Stack>
              <Card
                color={
                  item?.id === 1
                    ? "#00A9EE"
                    : item?.id === 2
                    ? "#007BAC"
                    : item?.id === 3
                    ? "#00486B"
                    : "red"
                }
                title={item?.name}
                icon={
                  item?.id === 1 ? (
                    <Box
                      sx={{
                        width: "46px",
                        height: "46px",
                        background: `transparent url(${noun_criminal_3678293}) 0% 0% no-repeat padding-box`,
                        opacity: 1,
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                      }}
                    />
                  ) : item?.id === 2 ? (
                    <Box
                      sx={{
                        width: "53px",
                        height: "53px",
                        background: `transparent url(${noun_risk_3338180}) 0% 0% no-repeat padding-box`,
                        opacity: 1,
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                      }}
                    />
                  ) : item?.id === 3 ? (
                    <Box
                      sx={{
                        width: "54px",
                        height: "53px",
                        background: `transparent url(${government}) 0% 0% no-repeat padding-box`,
                        opacity: 1,
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                      }}
                    />
                  ) : null
                }
                onClick={() => setIndicatorPanelCategory(item?.id)}
              />
              <Box
                position={"relative"}
                width="100%"
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                    xl: "block",
                  },
                }}
              >
                <Stack
                  position={"absolute"}
                  top="20px"
                  left={0}
                  height="100%"
                  width="100%"
                  justifyContent="center"
                  alignItems="center"
                >
                  {indicatorPanelCategory === item?.id && (
                    <PlayArrow
                      sx={{
                        transform: "rotate(-90deg)",
                        color: "#075E89",
                      }}
                    />
                  )}
                </Stack>
              </Box>
            </Stack>
          </Grid>
        ))}
        <Grid
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xl={12}
          item
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
              xl: "block",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "4px",
              background: "#075E89 0% 0% no-repeat padding-box",
              opacity: 1,
            }}
          />
        </Grid>
      </Grid>

      <IndicatorPanel
        category={categories?.find(
          (category) => category.id === indicatorPanelCategory
        )}
        locationId={locationId}
      />

      <IndicatorLegend 
        type={type}
        is_metropolitan_city={is_metropolitan_city}
      />

    </Stack>
  );
};

export { GroupIndicatorPanels };
