import React, { useCallback } from 'react';
import { ReactComponent as Algemas } from '../../../../assets/images/algemas.svg';
import { ReactComponent as Hands } from '../../../../assets/images/hands.svg';
import { ReactComponent as Government } from '../../../../assets/images/government.svg';

import {
  Container,
  Title,
  TitleText,
  Card,
  CardTitle,
  CardItem,
  IndicatorCard,
  IndicatorText,
  IconContainer,
  OtherIndicators,
  IndicatorItem,
} from './styles';


import flagGreen from "../../../../assets/images/flag-green.png";
import flagRed from "../../../../assets/images/flag-red.png";
import flagOrange from "../../../../assets/images/flag-orange.png";
import flagEmpty from "../../../../assets/images/flag-empty.png";
import metropGreen from "../../../../assets/images/metrop-green.png";
import metropRed from "../../../../assets/images/metrop-red.png";
import metropOrange from "../../../../assets/images/metrop-orange.png";
import metropEmpty from "../../../../assets/images/metrop-empty.png";
import regionGreen from "../../../../assets/images/region-green.png";
import regionRed from "../../../../assets/images/region-red.png";
import regionOrange from "../../../../assets/images/region-orange.png";
import regionEmpty from "../../../../assets/images/region-empty.png";
import periodOrange from "../../../../assets/images/period-orange.png";
import periodEmpty from "../../../../assets/images/period-empty.png";

import like from "../../../../assets/images/like.png";
import unlike from "../../../../assets/images/unlike.png";


function Page2({ data, printing }: any) {
  const getColor = useCallback((value: any) => {
    const colors: any = {
      '-1': '#FF8585',
      0: '#E09D39',
      1: '#139B66',
      2: '#C0C0C0',
    };
    return colors[value];
  }, []);

  return (
    <Container>
      <Title>Destaques positivos</Title>
      <TitleText>
        Nesta seção são apresentados os indicadores que tiveram desempenho acima
        da média em comparação com seus grupos de análise (acima da média
        nacional, acima da média regional etc.). Em destaque encontram-se os
        cinco indicadores que obtiveram o melhor desempenho no período de
        referência. Ao final da página, você tem acesso aos demais indicadores
        que se destacaram positivamente.
      </TitleText>
      {data.category.map((category: any, index: any) => (
        <Card printing={printing} key={index?.toString()}>
          <CardTitle
            color={
              category.category_name === 'Criminalidade e Violência'
                ? '#0c9fd8'
                : category.category_name === 'Desempenho Institucional'
                ? '#00486B'
                : category.category_name === 'Fatores de Risco'
                ? '#007BAC'
                : '#0c9fd8'
            }
          >
            {category.category_name === 'Criminalidade e Violência' && (
              <Algemas />
            )}
            {category.category_name === 'Desempenho Institucional' && (
              <Government />
            )}
            {category.category_name === 'Fatores de Risco' && <Hands />}
            {category.category_name}
          </CardTitle>

          {category.bests_indicators.map((indicator: any, index: any) => (
            <CardItem key={index?.toString()}>
              <IndicatorCard printing={printing}>
                <div className="titleContainer">
                  <h1>{indicator.name}</h1>
                  <small>{indicator.source}</small>
                </div>
                <div className="buttonsContainer">
                  <div className="IconContent">
                    <IconContainer color={getColor(indicator.evolution_cmp)}>
                      {indicator.evolution_cmp === 0 && (
                        <img src={periodOrange} alt="" />
                      )}
                      {indicator.evolution_cmp === 2 && (
                        <img src={periodEmpty} alt="" />
                      )}

                      {indicator.evolution_cmp === -1 && <img src={unlike} alt="" />}
                      {indicator.evolution_cmp === 1 && <img src={like} alt="" />}
                    </IconContainer>
                    {(data.is_state ||
                      (data.is_capital &&
                        !data.is_metropolitan_city &&
                        data.is_county &&
                        data.population > 100000)) && (
                      <IconContainer color={getColor(indicator.region_cmp)}>
                        {indicator.region_cmp === -1 && (
                          <img src={regionRed} alt="" />
                        )}
                        {indicator.region_cmp === 0 && (
                          <img src={regionOrange} alt="" />
                        )}
                        {indicator.region_cmp === 1 && (
                          <img src={regionGreen} alt="" />
                        )}
                        {indicator.region_cmp === 2 && (
                          <img src={regionEmpty} alt="" />
                        )}
                      </IconContainer>
                    )}
                    <IconContainer color={getColor(indicator.country_cmp)}>
                      {indicator.country_cmp === -1 && (
                        <img src={flagRed} alt="" />
                      )}
                      {indicator.country_cmp === 0 && (
                        <img src={flagOrange} alt="" />
                      )}
                      {indicator.country_cmp === 1 && (
                        <img src={flagGreen} alt="" />
                      )}
                      {indicator.country_cmp === 2 && (
                        <img src={flagEmpty} alt="" />
                      )}
                    </IconContainer>
                    {!data.is_state && (
                      <IconContainer color={getColor(indicator.state_cmp)}>
                        {indicator.state_cmp === -1 && (
                          <img src={regionRed} alt="" />
                        )}
                        {indicator.state_cmp === 0 && (
                          <img src={regionOrange} alt="" />
                        )}
                        {indicator.state_cmp === 1 && (
                          <img src={regionGreen} alt="" />
                        )}
                        {indicator.state_cmp === 2 && (
                          <img src={regionEmpty} alt="" />
                        )}
                      </IconContainer>
                    )}
                    {((data.is_capital && data.is_metropolitan_city) ||
                      (data.is_county && data.is_metropolitan_city)) && (
                      <IconContainer color={getColor(indicator.metropolis_cmp)}>
                        {indicator.metropolis_cmp === -1 && (
                          <img src={metropRed} alt="" />
                        )}
                        {indicator.metropolis_cmp === 0 && (
                          <img src={metropOrange} alt="" />
                        )}
                        {indicator.metropolis_cmp === 1 && (
                          <img src={metropGreen} alt="" />
                        )}
                        {indicator.metropolis_cmp === 2 && (
                          <img src={metropEmpty} alt="" />
                        )}
                      </IconContainer>
                    )}
                  </div>
                  <span>
                    Período:
                    <br /> {indicator.period}
                  </span>
                </div>
              </IndicatorCard>
              <IndicatorText
                dangerouslySetInnerHTML={{
                  __html: indicator.content,
                }}
              ></IndicatorText>
            </CardItem>
          ))}
          {!category.bests_indicators.length && (
            <span>
              O estado/município analisado não possui indicadores com
              performance positiva em todos os critérios avaliados (performance
              no passado recente e comparações com as médias nacional e
              regional/estadual).
            </span>
          )}
        </Card>
      ))}
      <Card printing={printing}>
        <OtherIndicators>
          <div className="title">
            <IconContainer color="#139B66">
              <img src={like} alt="" />
            </IconContainer>
            Demais indicadores com destaque positivo:
          </div>
          <IndicatorItem>
            {data.category[2].positive_performance.map((indicator: any) => (
              <span key={indicator}>{`${indicator}, `}</span>
            ))}
            {data.category[1].positive_performance.map((indicator: any) => (
              <span key={indicator}>{`${indicator}, `}</span>
            ))}
            {data.category[0].positive_performance.map((indicator: any) => (
              <span key={indicator}>{`${indicator}, `}</span>
            ))}
          </IndicatorItem>
        </OtherIndicators>
      </Card>
    </Container>
  );
}

export default Page2;
