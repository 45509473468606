import * as React from "react";
import { AppBar, Box, Button, Container, IconButton, Link, Stack, Toolbar, Typography, useScrollTrigger } from "@mui/material";
import logo_bid from "../assets/images/logo_bid.png";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import logo_eseguranca_novo from "../assets/images/logo_eseguranca_novo.png";
import { Menu, Search } from "@mui/icons-material";
import { SearchTextField } from "./searchTextField";
import { useState } from "react";
interface Props {
  window?: () => Window;
  children: React.ReactElement;
}


function ElevationScroll(props: Props) {
  const { children, window } = props;
  const location = useLocation();
  
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    color: "transparent",
    elevation: 0,
    sx: {
      background: !trigger ? location?.pathname === "/" ? "transparent" : "#074776 0% 0% no-repeat padding-box" : "#074776 0% 0% no-repeat padding-box",
      boxShadow: !trigger ? location?.pathname === "/" ? "none" : "0px 8px 11px #0000003B" : "0px 8px 11px #0000003B",
    }
  });
}

const Layout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [showSearch, setShowSearch] = useState(false);
  const [collapse, setCollapse] = useState(false);
  return (
    <>
      <ElevationScroll>
        <AppBar>
          {showSearch && (
            <Box
              sx={{
                top: 0,
                left: 0,
                height: "400px",
                position: "fixed",
                width: "100vw;",
              }}
              onMouseLeave={() => setShowSearch(false)}
            >
              <Toolbar />
              <Stack
                mt={1}
                flex={1}
                justifyContent="center"
                alignItems="center"
                gap={2}
                sx={{
                  height: '100%',
                  width: '100%',
                  background: 'rgba(7,71,118,0.95) 0% 0% no-repeat padding-box',
                }}  
              >
                <Typography
                  sx={{
                    color: 'rgb(255, 255, 255)',
                    fontSize: '24px',
                    fontfamily: "IBM Plex Sans",
                    fontWeight: 600,
                  }}
                >
                  Acesse os dados do seu estado ou município
                </Typography>
                <Box
                  sx={{
                    width: "526px"
                  }}
                >
                  <SearchTextField
                    onClick={(l: any) => {
                      if (l?.ibge_code) {
                        setShowSearch(false)
                        navigate(`/diagnostico/${l?.ibge_code}`);
                      }
                    }}
                  />
                </Box>
              </Stack>
            </Box>
          )}
          <Toolbar component={Container} maxWidth="lg">
            <Stack
              pt="18px"
              pb="18px"
              flex={1}
              flexDirection="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box
                onClick={() => navigate('/')}
                sx={{
                  width: "152px",
                  height: "33px",
                  marginTop: "3px",
                  backgroundImage: `url(${logo_eseguranca_novo})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  cursor: "pointer",
                }}
              />

              <IconButton
                onClick={() => setCollapse(!collapse)}
                sx={{
                  color: "#FFFFFF",
                  display: {
                    xs: 'block',
                    sm: 'block',
                    md: 'none',
                    lg: 'none',
                    xl: 'none',
                  }
                }}
              >
                <Menu />
              </IconButton>

              <Stack 
                gap={{
                  xs: 2,
                  sm: 3,
                  md: 3,
                  lg: 3,
                  xl: 3,
                }} 
                sx={{
                  zIndex: {
                    xs: -1,
                    sm: -1,
                    md: 0,
                    lg: 0,
                    xl: 0,
                  },
                  pl: {
                    xs: 2,
                    sm: 3,
                    md: 0,
                    lg: 0,
                    xl: 0,
                  },
                  pt: {
                    xs: 2,
                    sm: 3,
                    md: 0,
                    lg: 0,
                    xl: 0,
                  },
                  pb: {
                    xs: 2,
                    sm: 3,
                    md: 0,
                    lg: 0,
                    xl: 0,
                  },
                  left: {
                    xs: '0px',
                    sm: '0px',
                  },
                  top: {
                    xs: '83px',
                    sm: '83px',
                    md: '0px',
                    lg: '0px',
                    xl: '0px',
                  },
                  width: {
                    xs: '100%',
                    sm: '100%',
                    md: 'auto',
                    lg: 'auto',
                    xl: 'auto',
                  },
                  flexDirection:  {
                    xs: 'column',
                    sm: 'column',
                    md: 'row',
                    lg: 'row',
                    xl: 'row',
                  },
                  alignItems: {
                    xs: 'flex-start',
                    sm: 'flex-start',
                    md: 'center',
                    lg: 'center',
                    xl: 'center',
                  },
                  backgroundColor: {
                    xs: '#074776',
                    sm: '#074776',
                    md: 'transparent',
                    lg: 'transparent',
                    xl: 'transparent',
                  },
                  boxShadow: {
                    xs: '0px 8px 11px #0000003B',
                    sm: '0px 8px 11px #0000003B',
                    md: 'none',
                    lg: 'none',
                    xl: 'none',
                  },
                  position: {
                    xs: 'absolute',
                    sm: 'absolute',
                    md: 'relative',
                    lg: 'relative',
                    xl: 'relative',
                  },
                  display: {
                    xs: collapse ? 'flex' : 'none',
                    sm: collapse ? 'flex' : 'none',
                    md: 'flex',
                    lg: 'flex',
                    xl: 'flex',
                  }
                }}
              >
                <Typography
                  href="/"
                  component={Link}
                  sx={{
                    width: "50px",
                    height: "22px",
                    textAlign: "left",
                    font: "normal normal 600 16px/22px Open Sans",
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    textTransform: "uppercase",
                    opacity: 1,
                    textDecoration: "none",
                    '&:hover': {
                      textDecoration: "underline",
                    }
                  }}
                >
                  INÍCIO
                </Typography>

                <Typography
                  component={Link}
                  target="_blank"
                  href="/metodologia.pdf"
                  sx={{
                    width: "133px",
                    height: "22px",
                    textAlign: "left",
                    font: "normal normal 600 16px/22px Open Sans",
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    textTransform: "uppercase",
                    opacity: 1,
                    cursor: "pointer",
                    textDecoration: "none",
                    '&:hover': {
                      textDecoration: "underline",
                    }
                  }}
                >
                  A METODOLOGIA
                </Typography>

                <Button
                  startIcon={<Search />}
                  variant="contained"
                  sx={{
                    width: "243px",
                    height: "37px",
                    background: "#00A9EE 0% 0% no-repeat padding-box",
                    borderRadius: "8px",
                    textAlign: "center",
                    font: "normal normal 600 14px/19px Open Sans",
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    opacity: 1,
                    textTransform: "none",
                    '&:hover': {
                      background: '#35749B 0% 0% no-repeat padding-box',
                    }
                  }}
                  onMouseEnter={() => setShowSearch(true)}
                  
                >
                  Digite a cidade ou estado
                </Button>
              </Stack>

            </Stack>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
        
      {location?.pathname !== "/" && <Toolbar />}

      <Outlet />

      <Box
        sx={{
          pt: "41px",
          pb: "21px",
          background: "#074776 0% 0% no-repeat padding-box",
          opacity: 1,
        }}
      >
        <Container maxWidth="lg">
          
          <Stack
            sx={{
              flexDirection: {
                xs: 'column',
                sm: 'column',
                md: 'column',
                lg: 'row',
                xl: 'row',
              },
              justifyContent: {
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'space-between',
                xl: 'space-between',
              },
              alignItems: {
                xs: 'center',
                sm: 'center',
                md: 'center',
                lg: 'flex-start',
                xl: 'flex-start',
              }
            }}
            flexWrap="wrap"
            gap={6}
            mb="67px"
          >
            <Stack 
              gap={2}
              sx={{
                alignItems: {
                  xs: 'center',
                  sm: 'center',
                  md: 'center',
                  lg: 'flex-start',
                  xl: 'flex-start',
                }
              }}
            >
              <Typography
                sx={{
                  width: "79px",
                  height: "19px",
                  textAlign: "left",
                  font: "normal normal bold 14px/19px Open Sans",
                  letterSpacing: "0px",
                  color: "#FFFFFF",
                  opacity: 1,
                }}
              >
                Links úteis:
              </Typography>
              <Stack 
                gap={2} 
                flexWrap="wrap" 
                sx={{
                  height: {
                    xs: 'auto',
                    sm: 'auto',
                    md: 'auto',
                    lg: '90px',
                    xl: '90px',
                  }
                }}
              >
                <Typography
                  component={Link}
                  target="_blank"
                  href="/metodologia.pdf"
                  sx={{
                    mr: 2,
                    textAlign: {
                      xs: 'center',
                      sm: 'center',
                      md: 'center',
                      lg: 'left',
                      xl: 'left',
                    },
                    font: "normal normal normal 14px/19px Open Sans",
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    opacity: 1,
                    cursor: "pointer",
                    textDecoration: "none",
                    '&:hover': {
                      textDecoration: "underline",
                    }
                  }}
                >
                  - Metodologia
                </Typography>
                <Typography
                  component={Link}
                  target="_blank"
                  href="https://www.iadb.org/en/who-we-are/how-we-are-organized/institutions-development-sector"
                  sx={{
                    mr: 2,
                    textAlign: {
                      xs: 'center',
                      sm: 'center',
                      md: 'center',
                      lg: 'left',
                      xl: 'left',
                    },
                    font: "normal normal normal 14px/19px Open Sans",
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    opacity: 1,
                    textDecoration: "none",
                    '&:hover': {
                      textDecoration: "underline",
                    }
                  }}
                >
                  - Gerência de Instituições para o Desenvolvimento (IFD)
                </Typography>
                <Typography
                  component={Link}
                  target="_blank"
                  href="https://www.iadb.org/en/who-we-are/topics/reform-and-modernization-state"
                  sx={{
                    mr: 2,
                    textAlign: {
                      xs: 'center',
                      sm: 'center',
                      md: 'center',
                      lg: 'left',
                      xl: 'left',
                    },
                    font: "normal normal normal 14px/19px Open Sans",
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    opacity: 1,
                    textDecoration: "none",
                    '&:hover': {
                      textDecoration: "underline",
                    }
                  }}
                >
                  - Divisão de Inovações para Servir ao Cidadão (ICS)
                </Typography>
                <Typography
                  component={Link}
                  target="_blank"
                  href="https://www.iadb.org/en/who-we-are/topics/reformmodernization-state/citizen-security-and-justice"
                  sx={{
                    mr: 2,
                    textAlign: {
                      xs: 'center',
                      sm: 'center',
                      md: 'center',
                      lg: 'left',
                      xl: 'left',
                    },
                    font: "normal normal normal 14px/19px Open Sans",
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    opacity: 1,
                    textDecoration: "none",
                    '&:hover': {
                      textDecoration: "underline",
                    }
                  }}
                >
                  - Cluster de Segurança Cidadã e Justiça
                </Typography>
                <Typography
                  component={Link}
                  target="_blank"
                  href="https://plataformadeevidencias.iadb.org/"
                  sx={{
                    mr: 2,
                    textAlign: {
                      xs: 'center',
                      sm: 'center',
                      md: 'center',
                      lg: 'left',
                      xl: 'left',
                    },
                    font: "normal normal normal 14px/19px Open Sans",
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    opacity: 1,
                    textDecoration: "none",
                    '&:hover': {
                      textDecoration: "underline",
                    }
                  }}
                >
                  - Plataforma de Evidências em Segurança & Justiça
                </Typography>
                <Typography
                  component={Link}
                  target="_blank"
                  href="https://blogs.iadb.org/seguridad-ciudadana/es/"
                  sx={{
                    mr: 2,
                    textAlign: {
                      xs: 'center',
                      sm: 'center',
                      md: 'center',
                      lg: 'left',
                      xl: 'left',
                    },
                    font: "normal normal normal 14px/19px Open Sans",
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    opacity: 1,
                    textDecoration: "none",
                    '&:hover': {
                      textDecoration: "underline",
                    }
                  }}
                >
                  - Blog Sin Miedos
                </Typography>
              </Stack>
            </Stack>

            <Stack 
              gap={2}
              sx={{
                alignItems: {
                  xs: 'center',
                  sm: 'center',
                  md: 'center',
                  lg: 'flex-start',
                  xl: 'flex-start',
                },
              }}
            >
              <Typography
                sx={{
                  width: "74px",
                  height: "19px",
                  textAlign: {
                    xs: 'center',
                    sm: 'center',
                    md: 'center',
                    lg: 'left',
                    xl: 'left',
                  },
                  font: "normal normal bold 14px/19px Open Sans",
                  letterSpacing: "0px",
                  color: "#FFFFFF",
                  opacity: 1,
                }}
              >
                Realização
              </Typography>

              <Box
                onClick={() => window.open("https://www.iadb.org/pt-br", "_blank")}
                sx={{
                  cursor: "pointer",
                  width: "196px",
                  height: "72px",
                  background: `transparent url(${logo_bid}) 0% 0% no-repeat padding-box`,
                  opacity: 1,
                }}
              />
            </Stack>
          </Stack>

          <Stack gap={1} justifyContent="center" alignItems="center">
            <Typography
              sx={{
                textAlign: "center",
                font: "normal normal normal 12px/17px Open Sans",
                letterSpacing: "0px",
                color: "#FFFFFF",
                opacity: 1,
              }}
            >
              © 2023 Banco Interamericano de Desenvolvimento
            </Typography>
          </Stack>

        </Container>
      </Box>
    </>
  );
};

export { Layout };
