import { Box, Stack, Typography } from '@mui/material';
import * as React from 'react';

interface IDimensionsDiagnosticCard {
  content: any
  icon: any
  areaIconColor: any
  title: any,
  description: any,
  descriptionColor: any,
}

const DimensionsDiagnosticCard: React.FC<IDimensionsDiagnosticCard> = ({
  content,
  icon,
  areaIconColor,
  title,
  description,
  descriptionColor,
}) => {
  const [open, setOpen] = React.useState<boolean>(false)
  return (
    <Stack
      flexDirection="row"
      onClick={() => setOpen(!open)}
      sx={{
        width: "100%",
        height: "170px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        borderRadius: "20px",
        opacity: 1,
        pr: 3,
        pl: 3,
        position: "relative",
        cursor: "pointer",
      }}
    >
      <Stack
        flex={1}
        justifyContent="center"
        sx={{
          borderRadius: "20px",
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          height: "100%",
          width: "100%",
          position: "absolute",
          zIndex: 1,
          top: 0,
          left: 0,
          pr: 3,
          pl: 3,
          opacity: open ? 1 : 0,
        }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              xs: "normal normal normal 12px/17px Open Sans",
              sm: "normal normal normal 14px/19px Open Sans",
              md: "normal normal normal 14px/19px Open Sans",
              lg: "normal normal normal 14px/19px Open Sans",
              xl: "normal normal normal 14px/19px Open Sans",
            },
            letterSpacing: "0px",
            color: "#000000",
            opacity: 1,
          }}
        >
          {content}
        </Typography>
      </Stack>
      <Stack
        flexDirection="row"
        gap={3}
        flex={1}
        alignItems="center"
        sx={{
          background: "#FFFFFF 0% 0% no-repeat padding-box",
          borderRadius: "20px",
        }}
      >
        <Stack
          sx={{
            minWidth: {
              xs: "71px",
              sm: "101px",
              md: "101px",
              lg: "101px",
              xl: "101px",
            },
            minHeight: {
              xs: "71px",
              sm: "101px",
              md: "101px",
              lg: "101px",
              xl: "101px",
            },
            background: `${areaIconColor} 0% 0% no-repeat padding-box`,
            borderRadius: "20px",
            opacity: 1,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: {
                xs: "40px",
                sm: "60px",
                md: "60px",
                lg: "60px",
                xl: "60px",
              },
              height: {
                xs: "40px",
                sm: "60px",
                md: "60px",
                lg: "60px",
                xl: "60px",
              },
              background: `transparent url(${icon}) 0% 0% no-repeat padding-box`,
              backgroundSize: "contain",
              opacity: 1,
            }}
          />
        </Stack>
        <Stack>
          <Typography
            sx={{
              textAlign: "left",
              font: "normal normal bold 20px/27px Open Sans",
              letterSpacing: "0px",
              color: "#A3A3A3",
              opacity: 1,
            }}
          >
            {title}
          </Typography>
          <Typography
            sx={{
              textAlign: "left",
              font: "normal normal bold 20px/27px Open Sans",
              letterSpacing: "0px",
              color: descriptionColor,
              opacity: 1,
              width: "99%",
            }}
          >
            {description}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

export { DimensionsDiagnosticCard }