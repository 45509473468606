import * as React from "react";
import { GroupIndicatorPanels } from "./groupIndicatorPanels";

interface IIndicatorPanelDiagnostics {
  categories?: [];
  locationId?: any;
  type?: string;
  is_metropolitan_city?: boolean
}

const IndicatorPanelDiagnostics: React.FC<IIndicatorPanelDiagnostics> = ({
  categories,
  locationId,
  type,
  is_metropolitan_city
}) => {
  return <GroupIndicatorPanels categories={categories} is_metropolitan_city={is_metropolitan_city} locationId={locationId} type={type} />;
};

export { IndicatorPanelDiagnostics };
