import { useState, useEffect, useCallback, useRef } from 'react';
import { useParams } from 'react-router-dom';
import jsPDF from 'jspdf';
import Page1 from './pages/Page1';
import Page2 from './pages/Page2';
import Page3 from './pages/Page3';
import Page4 from './pages/Page4';
import { printDocumentPage, getPageCanvas } from './ExecutiveReportService';
import Logo from '../../assets/images/logo-bid.png';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';
import {
  Container,
  Header,
  HeaderContent,
  Main,
  MainContent,
  PrintDiv,
} from "./styles";
import { useHttp } from "../../hooks/useHttp";
import { Box, Button, CircularProgress, Stack } from "@mui/material";

const ExecutiveReport = () => {
  const printRef = useRef(null);
  const { locationId } = useParams();
  const [printing, setPrinting] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [graphData, setGraphData] = useState<any>({});
  const { request } = useHttp();

  const start = useCallback(
    async (locationId: any) => {
      await request(
        {
          method: "GET",
          url: `/report-location/${locationId}/`,
        },
        {
          onCallback: (response: any) => {
            const criminalidadeViolência = response.data.category.filter(
              (cat: any) => cat.category_name === "Criminalidade e Violência"
            )[0];

            const desempenhoInstitucional = response.data.category.filter(
              (cat: any) => cat.category_name === "Desempenho Institucional"
            )[0];

            const fatoresRisco = response.data.category.filter(
              (cat: any) => cat.category_name === "Fatores de Risco"
            )[0];

            setGraphData({
              ...response.data,
              category: [
                criminalidadeViolência,
                fatoresRisco,
                desempenhoInstitucional,
              ],
            });

            setLoading(false);
          },
        }
      );
    },
    [request]
  );

  useEffect(() => {
    if (locationId) {
      start(locationId);
    }
  }, [locationId, start]);

  const printDocument = useCallback(async () => {
    window.scrollTo(0, 0);
    setPrinting(true);
    setPage(1);
    setTimeout(async () => {
      const page1 = await getPageCanvas(printRef.current);
      setPage(2);

      const page2 = await getPageCanvas(printRef.current);
      setPage(3);

      const page3 = await getPageCanvas(printRef.current);
      setPage(4);

      const page4 = await getPageCanvas(printRef.current);
      setPage(1);

      const pdf = new jsPDF("p", "mm");
      await printDocumentPage(page1, pdf, true);
      await printDocumentPage(page2, pdf, true);
      await printDocumentPage(page3, pdf, true);
      await printDocumentPage(page4, pdf, false);
      window.open(pdf.output("bloburl"));

      setPrinting(false);
    }, 500);
  }, []);

  return (
    <Container>
      {loading ? (
        <Stack flex={1} justifyContent="center" alignItems="center">
          <CircularProgress />
        </Stack>
      ) : (
        <Box>
          {printing && (
            <Stack 
              flex={1} 
              justifyContent="center" 
              alignItems="center"
              sx={{
                background: "white",
                width: "100%",
                height: "100%",
                position: "absolute",
                zIndex: 1000,
              }}
            >
              <CircularProgress />
            </Stack>
          )}
          <PrintDiv printing={printing} ref={printRef}>
            <Header>
              <HeaderContent printing={printing}>
                <div className="optionsContainer">
                  <Stack
                    mt={1}
                    gap={1}
                  >
                    <Button
                      className="back"
                      type="button"
                      variant="text"
                      sx={{
                        textTransform: "none",
                        textDecoration: "none",
                        '&:hover': {
                          textDecoration: "underline",
                        },
                      }}
                      onClick={() => printDocument()}
                    >
                      Imprimir
                    </Button>
                  </Stack>
                  <div className="pages">
                    {page >= 2 && (
                      <FaArrowAltCircleLeft
                        size={20}
                        className="left"
                        onClick={() => setPage(page === 1 ? 1 : page - 1)}
                      />
                    )}
                    <span>{`Página ${page}/4`}</span>
                    {page <= 3 && (
                      <FaArrowAltCircleRight
                        size={20}
                        onClick={() => setPage(page === 4 ? 4 : page + 1)}
                        className="right"
                      />
                    )}
                  </div>
                </div>
                <div className="content">
                  {!!graphData.name && (
                    <>
                      <div className="textContent">
                        <h2>Relatório Executivo</h2>
                        {page === 1 && (
                          <>
                            <h1>{graphData.name}</h1>
                          </>
                        )}
                        {(page === 2 || page === 3) && <h1>Visão Geral</h1>}
                        {page === 4 && <h1>Termômetro Geral</h1>}
                      </div>
                      <div className="left-container">
                        <div className="img-container">
                          <img src={Logo} alt="Logo BID" />
                          {/* <img src={BndesLogo} alt="Logo BNDES" /> */}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </HeaderContent>
            </Header>
            <Main>
              <MainContent>
                {page === 1 && <Page1 data={graphData} printing={printing} />}
              </MainContent>
              <MainContent>
                {page === 2 && <Page2 data={graphData} printing={printing} />}
              </MainContent>
              <MainContent>
                {page === 3 && <Page3 data={graphData} printing={printing} />}
              </MainContent>
              <MainContent>
                {page === 4 && <Page4 data={graphData} printing={printing} />}
              </MainContent>
            </Main>
          </PrintDiv>
        </Box>
      )}
    </Container>
  );
};

export default ExecutiveReport;
