import { Stack } from "@mui/material";
import * as React from "react";
import { Step } from "./step";

interface IDiagnosticStepsButton {
  activeStep: number;
  setActiveStep: (step: number) => void;
}

const DiagnosticStepsButton: React.FC<IDiagnosticStepsButton> = ({
  activeStep,
  setActiveStep,
}) => {
  return (
    <Stack flexDirection="row">
      {[
        { title: "Passo 1", description: "Painel de Indicadores" },
        { title: "Passo 2", description: "Soluções e Evidências" },
      ]?.map((item: any, index) => {
        return (
          <Step
            active={activeStep >= index}
            index={index}
            key={item?.title}
            title={item.title}
            description={item.description}
            setActiveStep={setActiveStep}
          />
        );
      })}
    </Stack>
  );
};

export { DiagnosticStepsButton };
