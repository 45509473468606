import {
  Box,
  Button,
  Container,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import mapa_pessoas from "../assets/images/mapa_pessoas.png";
import icon_ionic_ios_checkmark_circle_outline from "../assets/images/icon_ionic_ios_checkmark_circle_outline.png";
import { InsertDriveFile } from "@mui/icons-material";
import banners_bid1_V2 from "../assets/images/banners_bid1_V2.png";
import banners_bid2_V2 from "../assets/images/banners_bid2_V2.png";
import banners_bid3_V2 from "../assets/images/banners_bid3_V2.png";
import noun_criminal_3678293 from "../assets/images/noun_criminal_3678293.png";
import noun_risk_3338180 from "../assets/images/noun_risk_3338180.png";
import government from "../assets/images/government.png";
import mockup_site1 from "../assets/images/mockup_site1.png";
import grupo1279 from "../assets/images/grupo1279.png";
import icon_awesome_external_link_alt from "../assets/images/icon_awesome_external_link_alt.png";
import banners_bid_newsletter from "../assets/images/banners_bid_newsletter.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";
import { SearchTextField } from "../components/searchTextField";
import { ReactComponent as NextIcon } from "../assets/images/next-icon.svg";
import { ReactComponent as PreviousIcon } from "../assets/images/previous-icon.svg";
import { useRef } from "react";
import { DimensionsDiagnosticCard } from "../components/dimensionsDiagnosticCard";

const settings = {
  loop: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  fade: true,
  dots: true,
};

const Slide = styled(Slider)`
  .slick-dots {
    position: absolute;
    background-color: transparent;
    z-index: 1;
    bottom: 35px;
    width: 100%;
    li {
      margin: 0px 5px;
      button {
        width: 65px;
        &:before {
          color: transparent;
          height: 15px;
          width: 15px;
          border: 1px solid #ffffff;
          border-radius: 100%;
          opacity: 1;
        }
      }
    }
  }
  .slick-dots li.slick-active button:before {
    color: #ffffff;
    border: none;
    font-size: 17px;
    top: -2px;
  }
`;

const Home = () => {
  const sliderRef = useRef<any>(null);
  const navigate = useNavigate();

  return (
    <>
      <Box
        sx={{
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Slide ref={sliderRef} {...settings}>
          <Stack
            sx={{
              height: "470px",
              backgroundImage: `url(${banners_bid1_V2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Container maxWidth="lg" sx={{ height: "100%" }}>
              <Stack
                flex={1}
                sx={{
                  height: "100%",
                  justifyContent: "center",
                  alignItems: {
                    xs: 'center',
                    sm: 'center',
                    md: 'center',
                    lg: 'flex-start',
                    xl: 'flex-start',
                  },
                }}
              >
                <Typography
                  sx={{
                    textAlign: {
                      xs: 'center',
                      sm: 'center',
                      md: 'center',
                      lg: 'left',
                      xl: 'left',
                    },
                    font: {
                      xs: "normal normal bold 23px/36px Open Sans",
                      sm: "normal normal bold 31px/45px Open Sans",
                      md: "normal normal bold 36px/49px Open Sans",
                      lg: "normal normal bold 36px/49px Open Sans",
                      xl: "normal normal bold 36px/49px Open Sans",
                    },
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    opacity: 1,
                  }}
                >
                  Bem-vinda(o) ao E-Segurança,
                </Typography>
                <Typography
                  sx={{
                    minWidth: {
                      xs: "85%",
                      sm: "528px",
                      md: "728px",
                      lg: "728px",
                      xl: "728px",
                    },
                    maxWidth: {
                      xs: "85%",
                      sm: "85%",
                      md: "728px",
                      lg: "728px",
                      xl: "728px",
                    },
                    textAlign: {
                      xs: 'center',
                      sm: 'center',
                      md: 'center',
                      lg: 'left',
                      xl: 'left',
                    },
                    font: {
                      xs: "normal normal normal 18px/28px Open Sans",
                      sm: "normal normal normal 25px/35px Open Sans",
                      md: "normal normal normal 29px/39px Open Sans",
                      lg: "normal normal normal 29px/39px Open Sans",
                      xl: "normal normal normal 29px/39px Open Sans",
                    },
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    opacity: 1,
                  }}
                >                 
                  A primeira plataforma de elaboração de diagnósticos
                  multidisciplinares baseados em dados para a área da
                  segurança cidadã do Brasil.
                </Typography>
              </Stack>
            </Container>
          </Stack>
          <Stack
            sx={{
              height: "470px",
              backgroundImage: `url(${banners_bid3_V2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Container maxWidth="lg" sx={{ height: "100%" }}>
              <Stack
                flex={1}
                sx={{
                  height: "100%",
                  justifyContent: "center",
                  alignItems: {
                    xs: 'center',
                    sm: 'center',
                    md: 'center',
                    lg: 'flex-start',
                    xl: 'flex-start',
                  },
                }}
              >
                <Typography
                  sx={{
                    textAlign: {
                      xs: 'center',
                      sm: 'center',
                      md: 'center',
                      lg: 'left',
                      xl: 'left',
                    },
                    font: {
                      xs: "normal normal bold 23px/36px Open Sans",
                      sm: "normal normal bold 31px/45px Open Sans",
                      md: "normal normal bold 36px/49px Open Sans",
                      lg: "normal normal bold 36px/49px Open Sans",
                      xl: "normal normal bold 36px/49px Open Sans",
                    },
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    opacity: 1,
                  }}
                >
                  Avalie a situação atual e a evolução recente
                </Typography>
                <Typography
                  sx={{
                    minWidth: {
                      xs: "85%",
                      sm: "528px",
                      md: "728px",
                      lg: "728px",
                      xl: "728px",
                    },
                    maxWidth: {
                      xs: "85%",
                      sm: "85%",
                      md: "728px",
                      lg: "728px",
                      xl: "728px",
                    },
                    textAlign: {
                      xs: 'center',
                      sm: 'center',
                      md: 'center',
                      lg: 'left',
                      xl: 'left',
                    },
                    font: {
                      xs: "normal normal normal 18px/28px Open Sans",
                      sm: "normal normal normal 25px/35px Open Sans",
                      md: "normal normal normal 29px/39px Open Sans",
                      lg: "normal normal normal 29px/39px Open Sans",
                      xl: "normal normal normal 29px/39px Open Sans",
                    },
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    opacity: 1,
                  }}
                >
                  de seu estado/município em indicadores -chave e
                  identifique os principais desafios a serem superados na
                  área da segurança cidadã
                </Typography>
              </Stack>
            </Container>
          </Stack>
          <Stack
            sx={{
              height: "470px",
              backgroundImage: `url(${banners_bid2_V2})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
            }}
          >
            <Container maxWidth="lg" sx={{ height: "100%" }}>
              <Stack
                flex={1}
                sx={{
                  height: "100%",
                  justifyContent: "center",
                  alignItems: {
                    xs: 'center',
                    sm: 'center',
                    md: 'center',
                    lg: 'flex-start',
                    xl: 'flex-start',
                  },
                }}
              >
                <Typography
                  sx={{
                    textAlign: {
                      xs: 'center',
                      sm: 'center',
                      md: 'center',
                      lg: 'left',
                      xl: 'left',
                    },
                    font: {
                      xs: "normal normal bold 23px/36px Open Sans",
                      sm: "normal normal bold 31px/45px Open Sans",
                      md: "normal normal bold 36px/49px Open Sans",
                      lg: "normal normal bold 36px/49px Open Sans",
                      xl: "normal normal bold 36px/49px Open Sans",
                    },
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    opacity: 1,
                  }}
                >
                  Aprofunde-se na compreensão
                </Typography>
                <Typography
                  sx={{
                    minWidth: {
                      xs: "85%",
                      sm: "528px",
                      md: "728px",
                      lg: "728px",
                      xl: "728px",
                    },
                    maxWidth: {
                      xs: "85%",
                      sm: "85%",
                      md: "728px",
                      lg: "728px",
                      xl: "728px",
                    },
                    textAlign: {
                      xs: 'center',
                      sm: 'center',
                      md: 'center',
                      lg: 'left',
                      xl: 'left',
                    },
                    font: {
                      xs: "normal normal normal 18px/28px Open Sans",
                      sm: "normal normal normal 25px/35px Open Sans",
                      md: "normal normal normal 29px/39px Open Sans",
                      lg: "normal normal normal 29px/39px Open Sans",
                      xl: "normal normal normal 29px/39px Open Sans",
                    },
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    opacity: 1,
                  }}
                >
                  dos principais fatores e determinantes da insegurança, da
                  criminalidade e da violência
                </Typography>
              </Stack>
            </Container>
          </Stack>
        </Slide>
        <Box
          sx={{
            width: "100%",
            position: "absolute",
            bottom: 0,
            zIndex: 1,
          }}
        >
          <Stack
            width="100%"
            flex={1}
            alignItems={"center"}
            justifyContent="center"
          >
            <Stack
              flexDirection="row"
              width="150px"
              height="1px"
              alignItems={"center"}
              position="relative"
            >
              <Box
                sx={{
                  position: "absolute",
                  top: -60,
                  left: -4,
                }}
              >
                <PreviousIcon onClick={() => sliderRef.current.slickPrev()} />
              </Box>
              <Box
                sx={{
                  position: "absolute",
                  top: -60,
                  right: 0,
                }}
              >
                <NextIcon onClick={() => sliderRef.current.slickNext()} />
              </Box>
            </Stack>
          </Stack>
        </Box>
      </Box>

      <Box>
        <Container maxWidth="lg">
          <Stack
            gap={1}
            mb="20px"
            sx={{
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
                xl: "row",
              },
              alignItems: {
                xs: "center",
                sm: "center",
                md: "center",
                lg: "flex-start",
                xl: "flex-start",
              },
            }}
            justifyContent="space-between"
          >
            <Stack 
              mt="47px" 
              gap={4} 
              id="situacao_busca"
              sx={{
                alignItems: {
                  xs: "center",
                  sm: "center",
                  md: "center",
                  lg: "flex-start",
                  xl: "flex-start",
                },
              }}
            >
              <Stack 
                gap={2}
                sx={{
                  alignItems: {
                    xs: "center",
                    sm: "center",
                    md: "center",
                    lg: "flex-start",
                    xl: "flex-start",
                  },
                }}
              >
                <Typography
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "419px",
                      md: "419px",
                      lg: "419px",
                      xl: "419px",
                    },
                    textAlign: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "left",
                      xl: "left",
                    },
                    font: {
                      xs: "normal normal bold 36px/50px Open Sans",
                      sm: "normal normal bold 38px/52px Open Sans",
                      md: "normal normal bold 38px/52px Open Sans",
                      lg: "normal normal bold 38px/52px Open Sans",
                      xl: "normal normal bold 38px/52px Open Sans",
                    },
                    letterSpacing: "0px",
                    color: "#000000",
                    opacity: 1,
                  }}
                >
                  Veja a situação da sua cidade ou estado
                </Typography>
                <Typography
                  sx={{
                    textAlign: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "left",
                      xl: "left",
                    },
                    font: {
                      xs: "normal normal 600 18px/25px Open Sans",
                      sm: "normal normal 600 20px/27px Open Sans",
                      md: "normal normal 600 20px/27px Open Sans",
                      lg: "normal normal 600 20px/27px Open Sans",
                      xl: "normal normal 600 20px/27px Open Sans",
                    },
                    letterSpacing: "0px",
                    color: "#074776",
                    opacity: 1,
                  }}
                >
                  Análise retrospectiva e diagnóstico situacional
                </Typography>
              </Stack>
              <Box
                sx={{
                  width: {
                    xs: "100%",
                    sm: "526px",
                    md: "526px",
                    lg: "526px",
                    xl: "526px",
                  }
                }}
              >
                <SearchTextField
                  onClick={(l: any) => {
                    if (l?.ibge_code) {
                      navigate(`/diagnostico/${l?.ibge_code}`);
                    }
                  }}
                />
              </Box>
              <Stack 
                gap={3}
                sx={{
                  alignItems: {
                    xs: "center",
                    sm: "center",
                    md: "center",
                    lg: "flex-start",
                    xl: "flex-start",
                  },
                }}
              >
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  <Box
                    sx={{
                      minWidth: "23px",
                      minHeight: "23px",
                      background: `transparent url(${icon_ionic_ios_checkmark_circle_outline}) 0% 0% no-repeat padding-box`,
                      opacity: 1,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                    }}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      font: {
                        xs: "normal normal 600 18px/25px Open Sans",
                        sm: "normal normal 600 20px/27px Open Sans",
                        md: "normal normal 600 20px/27px Open Sans",
                        lg: "normal normal 600 20px/27px Open Sans",
                        xl: "normal normal 600 20px/27px Open Sans",
                      },
                      letterSpacing: "0px",
                      color: "#636363",
                      opacity: 1,
                    }}
                  >
                    26 estados, DF e 5560+ municípios
                  </Typography>
                </Stack>
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  <Box
                    sx={{
                      minWidth: "23px",
                      minHeight: "23px",
                      background: `transparent url(${icon_ionic_ios_checkmark_circle_outline}) 0% 0% no-repeat padding-box`,
                      opacity: 1,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                    }}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      font: {
                        xs: "normal normal 600 18px/25px Open Sans",
                        sm: "normal normal 600 20px/27px Open Sans",
                        md: "normal normal 600 20px/27px Open Sans",
                        lg: "normal normal 600 20px/27px Open Sans",
                        xl: "normal normal 600 20px/27px Open Sans",
                      },
                      letterSpacing: "0px",
                      color: "#636363",
                      opacity: 1,
                    }}
                  >
                    100+ indicadores públicos
                  </Typography>
                </Stack>
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  <Box
                    sx={{
                      minWidth: "23px",
                      minHeight: "23px",
                      background: `transparent url(${icon_ionic_ios_checkmark_circle_outline}) 0% 0% no-repeat padding-box`,
                      opacity: 1,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                    }}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      font: {
                        xs: "normal normal 600 18px/25px Open Sans",
                        sm: "normal normal 600 20px/27px Open Sans",
                        md: "normal normal 600 20px/27px Open Sans",
                        lg: "normal normal 600 20px/27px Open Sans",
                        xl: "normal normal 600 20px/27px Open Sans",
                      },
                      letterSpacing: "0px",
                      color: "#636363",
                      opacity: 1,
                    }}
                  >
                    Séries históricas
                  </Typography>
                </Stack>
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  <Box
                    sx={{
                      minWidth: "23px",
                      minHeight: "23px",
                      background: `transparent url(${icon_ionic_ios_checkmark_circle_outline}) 0% 0% no-repeat padding-box`,
                      opacity: 1,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                    }}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      font: {
                        xs: "normal normal 600 18px/25px Open Sans",
                        sm: "normal normal 600 20px/27px Open Sans",
                        md: "normal normal 600 20px/27px Open Sans",
                        lg: "normal normal 600 20px/27px Open Sans",
                        xl: "normal normal 600 20px/27px Open Sans",
                      },
                      letterSpacing: "0px",
                      color: "#636363",
                      opacity: 1,
                    }}
                  >
                    Referenciais comparativos
                  </Typography>
                </Stack>
                <Stack flexDirection="row" alignItems="center" gap={1}>
                  <Box
                    sx={{
                      minWidth: "23px",
                      minHeight: "23px",
                      background: `transparent url(${icon_ionic_ios_checkmark_circle_outline}) 0% 0% no-repeat padding-box`,
                      opacity: 1,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                    }}
                  />
                  <Typography
                    sx={{
                      textAlign: "left",
                      font: {
                        xs: "normal normal 600 18px/25px Open Sans",
                        sm: "normal normal 600 20px/27px Open Sans",
                        md: "normal normal 600 20px/27px Open Sans",
                        lg: "normal normal 600 20px/27px Open Sans",
                        xl: "normal normal 600 20px/27px Open Sans",
                      },
                      letterSpacing: "0px",
                      color: "#636363",
                      opacity: 1,
                    }}
                  >
                    Análise executiva
                  </Typography>
                </Stack>
              </Stack>
            </Stack>

            <Stack
              mt="6px"
              sx={{
                height: {
                  xs: "320px",
                  sm: "567px",
                  md: "567px",
                  lg: "567px",
                  xl: "567px",
                },
                width: {
                  xs: "280px",
                  sm: "567px",
                  md: "567px",
                  lg: "567px",
                  xl: "567px",
                },
                background: `transparent url(${mapa_pessoas}) 0% 0% no-repeat padding-box`,
                opacity: 1,
                backgroundSize: "contain",
                backgroundPosition: "center",
              }}
            />
          </Stack>
        </Container>
      </Box>

      <Box
        sx={{
          opacity: 1,
          position: "relative",
          overflow: "hidden",
          pt: "30px",
          pb: "30px",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            height: "100%",
            width: "102%",
            zIndex: -1,
            top: 0,
            left: -6,
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage: `url(${grupo1279})`,
          }}
        />
        <Container maxWidth="lg">
          <Stack 
            gap={3}
          >
            <Stack 
              gap={1}
              sx={{
                alignItems: {
                  xs: "center",
                  sm: "center",
                  md: "center",
                  lg: "flex-start",
                  xl: "flex-start",
                },
              }}
            >
              <Typography
                sx={{
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "center",
                    lg: "left",
                    xl: "left",
                  },
                  font: {
                    xs: "normal normal bold 36px/50px Open Sans",
                    sm: "normal normal bold 38px/52px Open Sans",
                    md: "normal normal bold 38px/52px Open Sans",
                    lg: "normal normal bold 38px/52px Open Sans",
                    xl: "normal normal bold 38px/52px Open Sans",
                  },
                  letterSpacing: "0px",
                  color: "#FFFFFF",
                  opacity: 1,
                }}
              >
                Dimensões do diagnóstico
              </Typography>
              <Typography
                sx={{
                  maxWidth: "744px",
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "center",
                    lg: "left",
                    xl: "left",
                  },
                  font: {
                    xs: "normal normal 300 23px/33px Open Sans",
                    sm: "normal normal 300 29px/39px Open Sans",
                    md: "normal normal 300 29px/39px Open Sans",
                    lg: "normal normal 300 29px/39px Open Sans",
                    xl: "normal normal 300 29px/39px Open Sans",
                  },
                  letterSpacing: "0px",
                  color: "#FFFFFF",
                  opacity: 1,
                }}
              >
                03 dimensões essenciais à geração de resultados em segurança
                cidadã
              </Typography>
            </Stack>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <DimensionsDiagnosticCard
                  content="Engloba diferentes tipos de crimes e formas de violência, consolidando, assim, os principais indicadores de resultado finalístico associados à área da segurança pública."
                  icon={noun_criminal_3678293}
                  areaIconColor="#027BAC"
                  title="Dimensão 01"
                  description="Criminalidade e Violência"
                  descriptionColor="#027BAC"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <DimensionsDiagnosticCard
                  content="Reúne um conjunto de determinantes de ordem individual, familiar, comunitária ou socioeconômica que aumentam a probabilidade de incidência de uma ou mais formas de criminalidade e violência."
                  icon={noun_risk_3338180}
                  areaIconColor="#074776"
                  title="Dimensão 02"
                  description="Fatores de Risco"
                  descriptionColor="#074776"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
                <DimensionsDiagnosticCard
                  content="Abarca um conjunto de variáveis pertinentes à compreensão do “estado da arte” de cada ente federativo no que diz respeito às suas capacidades estatais e à cobertura e/ou qualidade de um conjunto de serviços públicos relevantes à atuação preventiva na área da segurança cidadã."
                  icon={government}
                  areaIconColor="#00A9EE"
                  title="Dimensão 03"
                  description="Desempenho Institucional"
                  descriptionColor="#074776"
                />
              </Grid>
            </Grid>
          </Stack>
        </Container>
      </Box>

      <Box
        sx={{
          mt: "53px",
          mb: "53px",
        }}
      >
        <Container maxWidth="lg">
          <Stack 
            gap={6}  
            justifyContent="space-between"
            sx={{
              alignItems:{
                xs: "center",
                sm: "center",
                md: "center",
                lg: "flex-start",
                xl: "flex-start",
              },
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
                xl: "row",
              }
            }}
          >
            <Stack 
              gap={3}
              sx={{
                alignItems: {
                  xs: "center",
                  sm: "center",
                  md: "center",
                  lg: "flex-start",
                  xl: "flex-start",
                }
              }}
            >
              <Typography
                sx={{
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "center",
                    lg: "left",
                    xl: "left",
                  },
                  font: "normal normal bold 38px/52px Open Sans",
                  letterSpacing: "0px",
                  color: "#000000",
                  opacity: 1,
                }}
              >
                O que pode ser feito?
              </Typography>

              <Typography
                sx={{
                  width: {
                    xs: "100%",
                    sm: "490px",
                    md: "490px",
                    lg: "490px",
                    xl: "490px",
                  },
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "center",
                    lg: "left",
                    xl: "left",
                  },
                  font: {
                    xs: "normal normal 300 17px/27px Open Sans",
                    sm: "normal normal 300 29px/39px Open Sans",
                    md: "normal normal 300 29px/39px Open Sans",
                    lg: "normal normal 300 29px/39px Open Sans",
                    xl: "normal normal 300 29px/39px Open Sans",
                  },
                  letterSpacing: "0px",
                  color: "#000000",
                  opacity: 1,
                }}
              >
                Acesse a Plataforma de Evidências em Segurança & Justiça e conheça tipos de soluções e casos de aplicação prática que efetivamente funcionaram para reduzir a criminalidade e a violência no Brasil e no mundo
              </Typography>

              <Button
                variant="contained"
                startIcon={
                  <Box
                    sx={{
                      width: "24px",
                      height: "21px",
                      background: `transparent url(${icon_awesome_external_link_alt}) 0% 0% no-repeat padding-box`,
                      opacity: 1,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                    }}
                  />
                }
                sx={{
                  width: "273px",
                  height: "60px",
                  background: "#074776 0% 0% no-repeat padding-box",
                  borderRadius: "10px",
                  opacity: 1,
                  textAlign: "center",
                  font: "normal normal bold 18px/24px Open Sans",
                  letterSpacing: "0px",
                  color: "#FFFFFF",
                  textTransform: "none",
                  "&:hover": {
                    background: "#35749B 0% 0% no-repeat padding-box",
                  },
                }}
                onClick={() =>
                  window.open("https://plataformadeevidencias.iadb.org/")
                }
              >
                Acesse a Plataforma
              </Button>
            </Stack>

            <Box
              sx={{
                position: "relative",
                width: {
                  xs: "100%",
                  sm: "536px",
                  md: "536px",
                  lg: "536px",
                  xl: "536px",
                },
                overflow: "hidden",
                height: "327px",
                boxShadow: "0px 3px 6px #00000029",
                borderRadius: "20px",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  height: {
                    xs: "105%",
                    sm: "105%",
                    md: "105%",
                    lg: "105%",
                    xl: "105%",
                  },
                  width: {
                    xs: "104%",
                    sm: "104%",
                    md: "104%",
                    lg: "104%",
                    xl: "104%",
                  },
                  top: -6,
                  left: -9,
                  backgroundImage: `url(${mockup_site1})`,
                  opacity: 1,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  cursor: "pointer",
                }}
                onClick={() =>
                  window.open("https://plataformadeevidencias.iadb.org/")
                }
              />
            </Box>
          </Stack>
        </Container>
      </Box>

      <Box
        sx={{
          background: "#F8F8F8 0% 0% no-repeat padding-box",
          opacity: 1,
          pt: "40px",
          pb: "40px",
        }}
      >
        <Container maxWidth="lg">
          <Stack 
            gap={3} 
            sx={{
              alignItems: {
                xs: "center",
                sm: "center",
                md: "center",
                lg: "flex-start",
                xl: "flex-start",
              },
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "column",
                lg: "row",
                xl: "row",
              }
            }} 
            justifyContent="space-between"
          >
            <Stack gap={1}
              sx={{
                alignItems: {
                  xs: "center",
                  sm: "center",
                  md: "center",
                  lg: "flex-start",
                  xl: "flex-start",
                }
              }}
            >
              <Typography
                sx={{
                  maxWidth: "523px",
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "center",
                    lg: "left",
                    xl: "left",
                  },
                  font: {
                    xs: "normal normal bold 36px/50px Open Sans",
                    sm: "normal normal bold 38px/52px Open Sans",
                    md: "normal normal bold 38px/52px Open Sans",
                    lg: "normal normal bold 38px/52px Open Sans",
                    xl: "normal normal bold 38px/52px Open Sans",
                  },
                  letterSpacing: "0px",
                  color: "#000000",
                  opacity: 1,
                }}
              >
                Como implantar soluções de segurança baseada em evidências?
              </Typography>
              <Typography
                sx={{
                  maxWidth: "533px",
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "center",
                    lg: "left",
                    xl: "left",
                  },
                  font: {
                    xs: "normal normal 300 18px/25px Open Sans",
                    sm: "normal normal 300 20px/27px Open Sans",
                    md: "normal normal 300 20px/27px Open Sans",
                    lg: "normal normal 300 20px/27px Open Sans",
                    xl: "normal normal 300 20px/27px Open Sans",
                  },
                  letterSpacing: "0px",
                  color: "#000000",
                  opacity: 1,
                }}
              >
                O Banco Interamericano de Desenvolvimento (BID) é a principal
                fonte de financiamento para o desenvolvimento da América Latina
                e o Caribe e possui uma equipe de especialistas com larga
                experiência na prestação de apoio técnico e financeiro para
                implantação de estratégias preventivas nas áreas de segurança
                cidadã e justiça
              </Typography>
            </Stack>
            <Stack 
              gap={3}
              sx={{
                flexDirection: {
                  xs: "column",
                  sm: "row",
                  md: "row",
                  lg: "row",
                  xl: "row",
                }
              }}
            >
              <Stack
                gap={1}
                sx={{
                  width: "260px",
                  height: "336px",
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "20px",
                  opacity: 1,
                  pt: "26px",
                  pb: "26px",
                  pl: "20px",
                  pr: "20px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Stack
                  gap={1}
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: "105px",
                    height: "105px",
                    background: "#D3EDFF 0% 0% no-repeat padding-box",
                    opacity: 1,
                    borderRadius: "50%",
                  }}
                >
                  <Typography
                    sx={{
                      width: "59px",
                      height: "71px",
                      textAlign: "center",
                      font: "normal normal bold 52px/71px Open Sans",
                      letterSpacing: "0px",
                      color: "#00A9EE",
                      opacity: 1,
                    }}
                  >
                    01
                  </Typography>
                </Stack>

                <Typography
                  sx={{
                    width: "200px",
                    height: "89px",
                    textAlign: "center",
                    font: "normal normal 600 20px/27px Open Sans",
                    letterSpacing: "0px",
                    color: "#074776",
                    opacity: 1,
                  }}
                >
                  Veja como obter uma operação de crédito com o BID
                </Typography>

                <Button
                  startIcon={<InsertDriveFile />}
                  variant="contained"
                  sx={{
                    width: "220px",
                    height: "35px",
                    background: "#074776 0% 0% no-repeat padding-box",
                    borderRadius: "10px",
                    opacity: 1,
                    textAlign: "left",
                    font: "normal normal bold 14px/19px Open Sans",
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    textTransform: "none",
                    "&:hover": {
                      background: "#35749B 0% 0% no-repeat padding-box",
                    },
                  }}
                  onClick={() => window.open("guia_informativo.pdf", "_blank")}
                >
                  Baixe o guia informativo
                </Button>
              </Stack>

              <Stack
                gap={1}
                sx={{
                  width: "260px",
                  height: "336px",
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                  boxShadow: "0px 3px 6px #00000029",
                  borderRadius: "20px",
                  opacity: 1,
                  pt: "26px",
                  pb: "26px",
                  pl: "20px",
                  pr: "20px",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Stack
                  gap={1}
                  justifyContent="center"
                  alignItems="center"
                  sx={{
                    width: "105px",
                    height: "105px",
                    background: "#D3EDFF 0% 0% no-repeat padding-box",
                    opacity: 1,
                    borderRadius: "50%",
                  }}
                >
                  <Typography
                    sx={{
                      width: "59px",
                      height: "71px",
                      textAlign: "center",
                      font: "normal normal bold 52px/71px Open Sans",
                      letterSpacing: "0px",
                      color: "#00A9EE",
                      opacity: 1,
                    }}
                  >
                    02
                  </Typography>
                </Stack>

                <Typography
                  sx={{
                    width: "200px",
                    height: "89px",
                    textAlign: "center",
                    font: "normal normal 600 20px/27px Open Sans",
                    letterSpacing: "0px",
                    color: "#074776",
                    opacity: 1,
                  }}
                >
                  Conheça o PROSEG Federativo, parceria entre o BID e o BNDES
                </Typography>

                <Button
                  startIcon={
                    <Box
                      sx={{
                        width: "14px",
                        height: "13px",
                        background: `transparent url(${icon_awesome_external_link_alt}) 0% 0% no-repeat padding-box`,
                        opacity: 1,
                        backgroundSize: "contain",
                        backgroundPosition: "center",
                      }}
                    />
                  }
                  variant="contained"
                  sx={{
                    width: "220px",
                    height: "35px",
                    background: "#074776 0% 0% no-repeat padding-box",
                    borderRadius: "10px",
                    opacity: 1,
                    textAlign: "left",
                    font: "normal normal bold 14px/19px Open Sans",
                    letterSpacing: "0px",
                    color: "#FFFFFF",
                    textTransform: "none",
                    "&:hover": {
                      background: "#35749B 0% 0% no-repeat padding-box",
                    },
                  }}
                  onClick={() =>
                    window.open(process.env.REACT_APP_PROSEG_URL, "_blank")
                  }
                >
                  Acesse o PROSEG
                </Button>
              </Stack>
            </Stack>
          </Stack>
        </Container>
      </Box>

      <Box
        sx={{
          background: `transparent url(${banners_bid_newsletter}) 0% 0% no-repeat padding-box`,
          opacity: 1,
          pt: "64px",
          pb: "64px",
          backgroundPosition: "center",
          backgroundSize: "cover",
        }}
      >
        <Container maxWidth="lg">
          <Stack
            gap={6}
            sx={{
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              },
              alignItems: "center"
            }}
            justifyContent="space-between"
          >
            <Stack 
              gap={1}
              sx={{
                alignItems: {
                  xs: "center",
                  sm: "center",
                  md: "flex-start",
                  lg: "flex-start",
                  xl: "flex-start",
                }
              }}
            >
              <Typography
                sx={{
                  width: "212px",
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "left",
                    lg: "left",
                    xl: "left",
                  },
                  font: {
                    xs: "normal normal bold 36px/50px Open Sans",
                    sm: "normal normal bold 38px/52px Open Sans",
                    md: "normal normal bold 38px/52px Open Sans",
                    lg: "normal normal bold 38px/52px Open Sans",
                    xl: "normal normal bold 38px/52px Open Sans",
                  },
                  letterSpacing: "0px",
                  color: "#FFFFFF",
                  opacity: 1,
                }}
              >
                Newsletter
              </Typography>
              <Typography
                sx={{
                  width: {
                    xs: "90%",
                    sm: "90%",
                    md: "100%",
                    lg: "100%",
                    xl: "100%",
                  },
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "left",
                    lg: "left",
                    xl: "left",
                  },
                  font: "normal normal 300 20px/27px Open Sans",
                  letterSpacing: "0px",
                  color: "#FFFFFF",
                  opacity: 1,
                }}
              >
                Fique por dentro das novidades sobre dados e evidências de
                Segurança e Justiça
              </Typography>
            </Stack>
            <Button
              variant="contained"
              sx={{
                width: "260px",
                height: "70px",
                background: "#FFFFFF 0% 0% no-repeat padding-box",
                border: "1px solid #D0D0D0",
                borderRadius: "10px",
                textAlign: "left",
                font: "normal normal bold 21px/28px Open Sans",
                letterSpacing: "0px",
                color: "#35749B",
                textTransform: "none",
                opacity: 1,
                "&:hover": {
                  color: "#00A9EE",
                  background: "#FFFFFF 0% 0% no-repeat padding-box",
                },
              }}
              onClick={() =>
                window.open(
                  "https://cloud.mail.iadb.org/idbSubscriptionForm?TForm=F&UTMC=7013s000000jH2FAAU&UTMM=Direct&UTMS=Website&lang=PT",
                  "_blank"
                )
              }
            >
              Inscreva-se
            </Button>
          </Stack>
        </Container>
      </Box>
    </>
  );
};

export { Home };
