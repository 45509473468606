import { Grid, Stack, Typography } from "@mui/material";
import * as React from "react";

import flagGreen from "../assets/images/flag-green.png";
import flagRed from "../assets/images/flag-red.png";
import flagOrange from "../assets/images/flag-orange.png";
import flagEmpty from "../assets/images/flag-empty.png";
import metropGreen from "../assets/images/metrop-green.png";
import metropRed from "../assets/images/metrop-red.png";
import metropOrange from "../assets/images/metrop-orange.png";
import metropEmpty from "../assets/images/metrop-empty.png";
import regionGreen from "../assets/images/region-green.png";
import regionRed from "../assets/images/region-red.png";
import regionOrange from "../assets/images/region-orange.png";
import regionEmpty from "../assets/images/region-empty.png";
import periodOrange from "../assets/images/period-orange.png";
import periodEmpty from "../assets/images/period-empty.png";
import like from "../assets/images/like.png";
import unlike from "../assets/images/unlike.png";

interface IndicatorLegendProps {
  type?: string;
  is_metropolitan_city?: boolean
}

const IndicatorLegend: React.FC<IndicatorLegendProps> = ({
  type,
  is_metropolitan_city
}) => {
  return (
    <Stack
      sx={{
        background:
          "0% 0% no-repeat padding-box padding-box rgb(248, 248, 248)",
        border: "1px solid rgb(241, 241, 241)",
        borderRadius: "10px",
        padding: "20px",
      }}
      gap={2}
    >
      <Typography
        sx={{
          textAlign: "left",
          font: "bold 16px / 20px IBM Plex Sans",
          letterSpacing: "0px",
          color: "rgb(4, 84, 124)",
        }}
      >
        Legenda:
      </Typography>

      <Grid container rowSpacing={6}>
        {(type === "city" || type === "state") && (
          <Grid item xs={12} sm={6} md={4} lg={type === "state" ? 4 : 3} xl={type === "state" ? 4 : 3}>
            <Stack gap={1}>
              <Typography
                sx={{
                  textAlign: "left",
                  font: "bold 14px / 18px IBM Plex Sans",
                  letterSpacing: "0px",
                  color: "rgb(109, 110, 113)",
                }}
              >
                Evolução
              </Typography>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={like}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Melhora no período
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={unlike}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Piora no período
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={periodOrange}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Estável no período
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={periodEmpty}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Não comparável
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        )}
        
        {(type === "city" || type === "state") && (
          <Grid item xs={12} sm={6} md={4} lg={type === "state" ? 4 : 3} xl={type === "state" ? 4 : 3}>
            <Stack gap={1}>
              <Typography
                sx={{
                  textAlign: "left",
                  font: "bold 14px / 18px IBM Plex Sans",
                  letterSpacing: "0px",
                  color: "rgb(109, 110, 113)",
                }}
              >
                Comparação com a média nacional
              </Typography>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={flagGreen}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Melhor que o país no último ano
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={flagRed}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Pior que o país no último ano
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={flagOrange}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Igual ao país no último ano
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={flagEmpty}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Não comparável
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        )}
        
        {type === "city" && (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Stack gap={1}>
              <Typography
                sx={{
                  textAlign: "left",
                  font: "bold 14px / 18px IBM Plex Sans",
                  letterSpacing: "0px",
                  color: "rgb(109, 110, 113)",
                }}
              >
                Comparação com a média estadual
              </Typography>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={regionGreen}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Melhor que o estado no último ano
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={regionRed}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Pior que o estado no último ano
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={regionOrange}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Igual o estado no último ano
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={regionEmpty}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Não comparável
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        )}
        
        {(type === "city" && is_metropolitan_city) && (
          <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
            <Stack gap={1}>
              <Typography
                sx={{
                  textAlign: "left",
                  font: "bold 14px / 18px IBM Plex Sans",
                  letterSpacing: "0px",
                  color: "rgb(109, 110, 113)",
                }}
              >
                Comparação com a região metropolitana
              </Typography>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={metropGreen}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Melhor que a região no último ano
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={metropRed}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Pior que a região no último ano
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={metropOrange}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Igual à região no último ano
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={metropEmpty}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Não comparável
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        )}

        {type === "state" && (
          <Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
            <Stack gap={1}>
              <Typography
                sx={{
                  textAlign: "left",
                  font: "bold 14px / 18px IBM Plex Sans",
                  letterSpacing: "0px",
                  color: "rgb(109, 110, 113)",
                }}
              >
                Comparação com a média regional
              </Typography>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={regionGreen}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Melhor que a região no último ano
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={regionRed}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Pior que a região no último ano
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={regionOrange}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Igual a região no último ano
                </Typography>
              </Stack>
              <Stack flexDirection="row" alignItems="center" gap={1}>
                <img
                  src={regionEmpty}
                  alt=""
                  style={{ height: "33px", width: "33px" }}
                />
                <Typography
                  sx={{
                    textAlign: "left",
                    font: "12px / 15px IBM Plex Sans",
                    letterSpacing: "0px",
                    color: "rgb(109, 110, 113)",
                  }}
                >
                  Não comparável
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        )}

      </Grid>
    </Stack>
  );
};

export { IndicatorLegend };
