import { Box, Button, Stack, Typography } from "@mui/material";
import * as React from "react";
import icon_open_collapse_down from "../assets/images/icon_open_collapse_down.png";
import { AccordionIndicatorPanel } from "./accordionIndicatorPanel";

interface IIndicatorPanel {
  category: any;
  locationId?: any;
}

const IndicatorPanel: React.FC<IIndicatorPanel> = ({
  category,
  locationId,
}) => {
  const [collapsed, setCollapsed] = React.useState<any>([]);
  const [openAll, setOpenAll] = React.useState<boolean>(false);

  React.useEffect(() => {
    let obj: any = [];
    category.sub_categories.forEach((category: any) => {
      obj.push(false);
    });
    setCollapsed([...obj]);
  }, [category]);
  return (
    <Stack gap={4}>
      <Typography
        sx={{
          textAlign: "center",
          font: {
            xs: "normal normal bold 28px/32px Open Sans",
            sm: "normal normal bold 56px/76px Open Sans",
            md: "normal normal bold 56px/76px Open Sans",
            lg: "normal normal bold 56px/76px Open Sans",
            xl: "normal normal bold 56px/76px Open Sans",
          },
          letterSpacing: "0px",
          color: "#075E89",
          opacity: 1,
        }}
      >
        {category?.name}
      </Typography>

      <Stack gap={2}>
        <Stack
          flexDirection="row"
          alignItems="center"
          justifyContent="flex-end"
        >
          <Button
            startIcon={
              <Box
                sx={{
                  width: "14px",
                  height: "14px",
                  background: `transparent url(${icon_open_collapse_down}) 0% 0% no-repeat padding-box`,
                  opacity: 1,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                }}
              />
            }
            sx={{
              height: "19px",
              textAlign: "left",
              font: "normal normal bold 14px/19px Open Sans",
              letterSpacing: "0px",
              color: "#074776",
              opacity: 1,
              textTransform: "none",
              "&:hover": {
                color: "#35749B",
              },
            }}
            onClick={() => {
              let obj: any = collapsed;
              obj = obj.map((item: any) => !openAll);
              setCollapsed([...obj]);
              setOpenAll(!openAll);
            }}
          >
            Abrir todos
          </Button>
        </Stack>

        {category?.sub_categories?.map((item: any, index: any) => {
          return (
            <AccordionIndicatorPanel
              key={index?.toString()}
              defaultExpanded={false}
              expanded={collapsed[index]}
              title={item?.name}
              locationId={locationId}
              subCategoryId={item?.id}
              setCollapsed={() => {
                let obj: any = collapsed;
                obj[index] = !obj[index];
                setCollapsed([...obj]);
              }}
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

export { IndicatorPanel };
