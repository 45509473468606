import styled, { keyframes } from 'styled-components';

const EaseIn = keyframes`
  from{ opacity:0; }
  to{ opacity:1; }
`;

export const Container = styled.div`
  animation: ${EaseIn} 0.3s;
`;

export const Header = styled.div`
  background: #f8f8f8;
  min-height: 235px;
`;

export const HeaderContent = styled.section<any>`
  padding-top: 27px;
  max-width: 1004px;
  margin: 0 auto;

  .optionsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 18px;

    .back {
      text-align: left;
      text-decoration: underline;
      font: normal normal normal 20px/25px IBM Plex Sans;
      letter-spacing: 0px;
      color: #004e70;
      opacity: 1;
      display: ${props => (props.printing ? 'none' : 'block')};
    }

    .pages {
      display: ${props => (props.printing ? 'none' : 'flex')};
      align-items: center;
      justify-content: space-between;

      span {
        text-align: center;
        font: normal normal bold 14px/18px IBM Plex Sans;
        letter-spacing: 0px;
        color: #6d6e71;
        opacity: 1;
      }

      .left {
        margin-right: 5px;
        cursor: pointer;
      }

      .right {
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }

  .content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1004px;
    margin: 0 auto;

    .img-container {
      border-radius: 5px;
      width: 190px;
      margin: 10px 0;

      img {
        width: 100%;
        margin-left: 26px;
      }
    }

    .textContent {
      h2 {
        text-align: left;
        font: normal normal bold 25px/33px IBM Plex Sans;
        letter-spacing: 0px;
        color: #6d6e71;
        opacity: 1;
      }

      h1 {
        text-align: left;
        font: normal normal bold 55px/71px IBM Plex Sans;
        letter-spacing: 0px;
        color: #00a9ee;
      }

      .ranking {
        max-width: 190px;
        height: 38px;
        background: #00a9ee;

        border-radius: 9px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 15px;

        small {
          text-align: left;
          font: normal normal medium 16px/25px IBM Plex Sans;
          letter-spacing: 0px;
          color: #f8f8f8;
        }

        span {
          text-align: left;
          font: normal normal bold 20px/25px IBM Plex Sans;
          letter-spacing: 0px;
          color: #f8f8f8;
        }
      }
    }

    .left-container {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .graphContent {
      display: flex;
      align-items: center;
      small {
        text-align: center;
        font: normal normal normal 22px/29px IBM Plex Sans;
        letter-spacing: 0px;
        color: #aab9bf;
        opacity: 1;
        margin-right: 5px;
      }
    }
  }
`;

export const Main = styled.div``;

export const MainContent = styled.div`
  margin-top: 15px;
  max-width: 1004px;
  margin: 0 auto;
`;

export const PrintDiv = styled.div<any>`
  width: ${props => (props.printing ? '1200px' : 'inherit')};
`;
