import { ArrowRight } from "@mui/icons-material";
import { Box, Stack, Typography } from "@mui/material";
import * as React from "react";

interface IStep {
  index: number;
  title: string;
  description: string;
  active?: boolean;
  setActiveStep?: (step: number) => void;
}

const Step: React.FC<IStep> = ({
  index,
  title,
  description,
  active,
  setActiveStep,
}) => {
  return (
    <Stack
      gap={1}
      zIndex={index === 0 ? 1 : 0}
      marginRight={index > 0 ? "0px" : "-4px"}
    >
      <Typography
        sx={{
          ml: index === 0 ? 0 : 2,
          height: "19px",
          textAlign: "left",
          font: "normal normal normal 14px/19px Open Sans",
          letterSpacing: "0px",
          color: "#004E70",
          opacity: 1,
        }}
      >
        {title}
      </Typography>
      <Stack
        sx={{
          p: 2,
          pl: index === 0 ? 2 : 4,
          height: "36px",
          background: active
            ? "#004E70 0% 0% no-repeat padding-box"
            : "#F1F1F1 0% 0% no-repeat padding-box",
          border: `1px solid ${active ? "#004E70" : "#FFFFFF"}`,
          borderRadius: "5px",
          borderTopRightRadius: index === 0 ? "0px" : "5px",
          borderBottomRightRadius: index === 0 ? "0px" : "5px",
          opacity: 1,
          position: "relative",
          cursor: "pointer",
        }}
        justifyContent="center"
        alignItems="center"
        onClick={() => setActiveStep && setActiveStep(index)}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: {
              xs: `normal normal ${
                active ? "bold" : "normal"
              } 11px/19px Open Sans`,
              sm: `normal normal ${
                active ? "bold" : "normal"
              } 12px/19px Open Sans`,
              md: `normal normal ${
                active ? "bold" : "normal"
              } 12px/19px Open Sans`,
              lg: `normal normal ${
                active ? "bold" : "normal"
              } 12px/19px Open Sans`,
              xl: `normal normal ${
                active ? "bold" : "normal"
              } 12px/19px Open Sans`,
            },
            letterSpacing: "0px",
            color: active ? "#FFFFFF" : "#6D6E71",
            opacity: 1,
          }}
        >
          {description}
        </Typography>
        {index === 0 && (
          <Box position="absolute" right={-50} top={-25.5} zIndex={1}>
            <ArrowRight
              sx={{
                color: "#004E70",
                height: "85px",
                width: "85px",
              }}
            />
          </Box>
        )}
      </Stack>
    </Stack>
  );
};

export { Step };
