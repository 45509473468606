import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Layout } from "../components/layout";
import { Home } from "./home";
import { Diagnostic } from "./diagnostic";
import { Indicator } from "./indicator";
import ExecutiveReport from "./ExecutiveReport";

const Pages = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/diagnostico" element={<Diagnostic />} />
          <Route path="/diagnostico/:ibge" element={<Diagnostic />} />
          <Route path="/diagnostico/:location/indicadores/:indicator" element={<Indicator />} />
          <Route path="/relatorio-executivo/:locationId" element={<ExecutiveReport />} />
      
          {/* <Route path="dashboard" element={<Dashboard />} /> */}

          {/* <Route path="*" element={<NoMatch />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export { Pages };
