import {
  Box,
  CircularProgress,
  InputAdornment,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  TextField,
} from "@mui/material";
import * as React from "react";
import { TextFieldDebounce } from "./textFieldDebounce";
import { useHttp } from "../hooks/useHttp";
import { Search } from "@mui/icons-material";

interface IComponent {
  onClick: any;
}

const SearchTextField: React.FC<IComponent> = ({ onClick }) => {
  const { request, loading } = useHttp();
  const [locations, setLocations] = React.useState<any>([]);
  const campoTextoRef = React.useRef<any>(null);

  const getLocations = React.useCallback(
    async (value: string) => {
      await request(
        {
          method: "GET",
          url: `/locations/?name=${value}`,
        },
        {
          onCallback: (response: any) => {
            setLocations(response?.data?.results);
          },
        }
      );
    },
    [request]
  );

  const onCallback = React.useCallback(
    (value: any) => {
      if (value !== "") {
        getLocations(value);
      } else {
        setLocations([]);
      }
    },
    [getLocations]
  );

  return (
    <Box sx={{ position: "relative", width: '100%' }}>
      <TextFieldDebounce
        onCallback={onCallback}
        input={({ onChange }: any) => (
          <TextField
            inputRef={campoTextoRef}
            sx={{
              textAlign: "left",
              fontFamily: "Open Sans",
              font: "normal normal normal 20px/27px Open Sans",
              letterSpacing: "0px",
              color: "#AAB9BF",
              opacity: 1,
              height: "60px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "11px",
                border: `none`,
                backgroundColor: "#fff",
              },
              "& .MuiOutlinedInput-notchedOutline": {
                border: `2px solid #00A9EE`,
                "&:hover": {
                  border: `2px solid #00A9EE !important`,
                  borderColor: "#00A9EE !important",
                },
              },
              "& .MuiInputBase-root": {
                border: `none`,
                paddingRight: "9px !important",
               
                
              },
              "& .MuiTextField-root": {
                borderRadius: "0",
                border: `none`,
                backgroundColor: "red",
              },
              "& .MuiFormControl-root": {
                borderRadius: "0",
                border: `none`,
                backgroundColor: "red",
              },
            }}
            placeholder="Digite a cidade ou estado"
            onChange={onChange}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search 
                    color="primary" 
                    fontSize="large" 
                  />
                </InputAdornment>
              ),
            }}
          />
        )}
      />

      {locations && locations.length > 0 && (
        <List
          sx={{
            overflow: "auto",
            maxHeight: "200px",
            position: "absolute",
            top: 62,
            width: "100%",
            zIndex: 2,
          }}
          component={Paper}
        >
          {loading ? (
            <Stack
              justifyContent={"center"}
              alignItems={"center"}
              sx={{ padding: "2px" }}
            >
              <CircularProgress />
            </Stack>
          ) : (
            locations?.map((location: any) => {
              return (
                <ListItemButton
                  key={location?.id}
                  onClick={() => {
                    onClick(location);
                    setLocations([]);
                    campoTextoRef.current.value = ""; 
                  }}
                >
                  <ListItemText primaryTypographyProps={{ sx: { fontFamily: 'Open Sans' } }} primary={location.complete_city_name} />
                </ListItemButton>
              );
            })
          )}
        </List>
      )}
    </Box>
  );
};

export { SearchTextField };
