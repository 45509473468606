import { Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import * as React from 'react'

interface ITableChart {
  data: any;
  label: any
  options: any;
  key?: any;
}

const TableChart: React.FC<ITableChart> = ({
  data,
  label,
  options,
  key
}) => {

  let columns: any[] = label?.map((label: any, index: any) => {
    return  { 
      field: label, 
      headerName: label, 
      flex: index === 0 ? 2 : 1, 
      minWidth: index === 0 ? 200 : 90,
      maxWidth: index === 0 ? 200 : 'auto',
      headerAlign: index === 0 ? 'left' : 'center',
      editable: false,      
      renderCell: (params: any) => (
        <Typography component="div" sx={{ whiteSpace: 'pre-line', width: '100%', textAlign: index === 0 ? 'left' : 'center', overflow: index === 0 ? 'auto' : 'hidden' }}>
          {Number(params.row[label]) ? Number(params.row[label])?.toLocaleString('pt-BR') : params.row[label]}
        </Typography>
      )
    }
  })

  let rows: any[] = data?.map((row: any, _: any) => {
    let hh: any = {}
    label.forEach((label: any, index: any) => {
      hh[label] = row[index]
    })
    return hh
  })

  return (
   <DataGrid
      rows={rows}
      columns={columns}
      getRowId={(row) => row?.Localidade}
      hideFooter={true}
      disableColumnMenu={true}
      getRowHeight={(row: any) => {
        if (row?.id?.length >= 10 && row?.id?.length < 20) return 60
        if (row?.id?.length >= 20 && row?.id?.length < 40) return 80
        if (row?.id?.length >= 40 && row?.id?.length < 60) return 100
        if (row?.id?.length >= 60) return 120
        return 40
      }}
    />
  )
}

export { TableChart }