import { CssBaseline, Theme, ThemeProvider, createTheme } from "@mui/material";
import * as React from "react";
import { ptBR as corePtBR } from "@mui/material/locale";
import { ptBR as ptBRGrid } from '@mui/x-data-grid'
import '@fontsource/open-sans';
import "@fontsource/ibm-plex-sans";

interface IContext {}

const Context = React.createContext<IContext>({});

interface IProvider {
  children: React.ReactNode;
}

const Provider: React.FC<IProvider> = ({ children }) => {
  const theme: Theme = createTheme(
    {
   
    },
    corePtBR,
    ptBRGrid
  );
  const value = React.useMemo(
    () => ({
      theme,
    }),
    [theme]
  );
  return (
    <Context.Provider value={value}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </Context.Provider>
  );
};

export type { IContext };

export { Context, Provider };
