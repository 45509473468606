import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import * as React from "react";
import icon_ionic_ios_arrow_down from "../assets/images/icon_ionic_ios_arrow_down.png";
import { useHttp } from "../hooks/useHttp";
import { CardIndicator } from "./cardIndicator";
interface IAccordionIndicatorPanel {
  title: string;
  defaultExpanded: boolean;
  locationId?: any;
  subCategoryId?: any;
  expanded: boolean;
  setCollapsed: any;
}

const AccordionIndicatorPanel: React.FC<IAccordionIndicatorPanel> = ({
  title,
  defaultExpanded,
  locationId,
  subCategoryId,
  expanded,
  setCollapsed,
}) => {
  const { request, loading } = useHttp();

  const [indicators, setIndicators] = React.useState<any[]>([]);

  const getIndicators = React.useCallback(async () => {
    try {
      await request(
        {
          method: "GET",
          url: `/locations/${locationId}/sub-category/${subCategoryId}/indicators/`,
        },
        {
          onCallback: (response: any) => {
            setIndicators(response?.data?.results);
          },
        }
      );
    } catch (e) {
      console.log({ e });
    }
  }, [locationId, request, subCategoryId]);

  React.useEffect(() => {
    getIndicators();
  }, [getIndicators]);
  if (indicators.length <= 0) {
    return null;
  }
  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      expanded={expanded}
      sx={{
        background: "#F8F8F8 0% 0% no-repeat padding-box",
        border: "1px solid #D0D0D0",
        borderRadius: "10px !important",
        opacity: 1,
      }}
      onChange={() => {
        setCollapsed();
      }}
    >
      <AccordionSummary
        expandIcon={
          <Box
            sx={{
              width: "17px",
              height: "10px",
              background: `transparent url(${icon_ionic_ios_arrow_down}) 0% 0% no-repeat padding-box`,
              opacity: 1,
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          />
        }
        sx={{
          minHeight: "65px",
        }}
      >
        <Typography
          sx={{
            textAlign: "left",
            font: "normal normal bold 16px/22px Open Sans",
            letterSpacing: "0px",
            color: "#6D6E71",
            opacity: 1,
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {loading && <LinearProgress />}
        {!loading && (
          <Grid spacing={3} container>
            {indicators?.map((item: any, index: any) => {
              return (
                <Grid item xs={12} md={4} key={index?.toString()}>
                  <CardIndicator
                    locationId={locationId}
                    shortName={item?.indicator?.short_name}
                    source={item?.indicator?.source}
                    indicatorId={item?.indicator?.id}
                    icons={item?.icons}
                    period={item?.indicator?.period}
                  />
                </Grid>
              );
            })}
          </Grid>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export { AccordionIndicatorPanel };
