import * as React from "react";
import { Pages } from "./pages";
import { Provider as ThemeProvider } from "./contexts/theme";
import { SnackbarProvider } from "notistack";

const App = () => {
  return (
    <ThemeProvider>
      <SnackbarProvider>
        <Pages />
      </SnackbarProvider>
    </ThemeProvider>
  );
};

export default App;
