import { Box, Divider, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import search_white from "../assets/images/search_white.png";
import flagGreen from "../assets/images/flag-green.png";
import flagRed from "../assets/images/flag-red.png";
import flagOrange from "../assets/images/flag-orange.png";
import flagEmpty from "../assets/images/flag-empty.png";
import metropGreen from "../assets/images/metrop-green.png";
import metropRed from "../assets/images/metrop-red.png";
import metropOrange from "../assets/images/metrop-orange.png";
import metropEmpty from "../assets/images/metrop-empty.png";
import regionGreen from "../assets/images/region-green.png";
import regionRed from "../assets/images/region-red.png";
import regionOrange from "../assets/images/region-orange.png";
import regionEmpty from "../assets/images/region-empty.png";
import periodOrange from "../assets/images/period-orange.png";
import periodEmpty from "../assets/images/period-empty.png";
import like from "../assets/images/like.png";
import unlike from "../assets/images/unlike.png";

interface ICardIndicator {
  locationId: any;
  shortName: any;
  source: any;
  indicatorId: any;
  icons: any;
  period: any;
}

const selectIcon: any = {
  "state-green.png": regionGreen,
  "state-red.png": regionRed,
  "state-grey.png": regionOrange,
  "state-empty.png": regionEmpty,
  "flag-green.png": flagGreen,
  "flag-red.png": flagRed,
  "flag-grey.png": flagOrange,
  "flag-empty.png": flagEmpty,
  "metrop-green.png": metropGreen,
  "metrop-red.png": metropRed,
  "metrop-grey.png": metropOrange,
  "metrop-empty.png": metropEmpty,
  "region-green.png": regionGreen,
  "region-red.png": regionRed,
  "region-grey.png": regionOrange,
  "region-empty.png": regionEmpty,
  "period-green.png": like,
  "period-red.png": unlike,
  "period-grey.png": periodOrange,
  "period-empty.png": periodEmpty,
  "like.png": like,
  "unlike.png": unlike
};

const CardIndicator: React.FC<ICardIndicator> = ({
  locationId,
  shortName,
  source,
  indicatorId,
  icons,
  period
}) => {
  const navigate = useNavigate()
  return (
    <Stack
      gap={2}
      justifyContent="space-between"
      sx={{
        p: 1.5,
        minHeight: "180px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        border: "1px solid #075E89",
        borderRadius: "5px",
        opacity: 1,
      }}
    >
      <Stack
        gap={1}
        flexDirection="row"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Stack gap={0} sx={{ maxWidth: "80%", }}>
          <ListItem  disablePadding>
            <ListItemText 
              primary={shortName}
              primaryTypographyProps={{
                sx: {
                  textAlign: "left",
                  font: "normal normal bold 15px/20px Open Sans",
                  letterSpacing: "0px",
                  color: "#075E89",
                  opacity: 1,
                }
              }}
              secondary={source}
              secondaryTypographyProps={{
                sx: {
                  textAlign: "left",
                  font: "normal normal normal 11px/15px Open Sans",
                  letterSpacing: "0px",
                  color: "#6D6E71",
                  opacity: 1,
                }
              }}
            />
          </ListItem>

        </Stack>

        <Stack
          onClick={() => navigate(`/diagnostico/${locationId}/indicadores/${indicatorId}`)}
          sx={{
            justifyContent: "center",
            alignItems: "center",
            minWidth: "38px",
            minHeight: "38px",
            background: "#04547C 0% 0% no-repeat padding-box",
            borderRadius: "8px",
            opacity: 1,
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              backgroundPosition: "center",
              backgroundSize: "contain",
              minWidth: "18px",
              minHeight: "18px",
              background: `transparent url(${search_white}) 0% 0% no-repeat padding-box`,
              opacity: 1,
            }}
          />
        </Stack>
      </Stack>

      <Stack gap={1}>
        <Divider variant="fullWidth" />
        <Stack
          gap={1}
          flexDirection="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Stack
            gap={1}
            sx={{
              flexDirection: "row",
              flexWrap: "nowrap",
              alignItems: "flex-start",
              justifyContent: "flex-start",
            }}
          >
            {icons?.map((icon: any, _: any) => {
              return (
                <Box
                  key={icon?.icon_name}
                  sx={{
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                    minWidth: "33px",
                    minHeight: "33px",
                    backgroundImage: `url(${selectIcon[icon?.icon_name]})`,
                    opacity: 1,
                  }}
                />
              )
            })}
          </Stack>
          <Typography
            sx={{
              width: "70px",
              textAlign: "left",
              font: "normal normal normal 12px/17px Open Sans",
              letterSpacing: "0px",
              color: "#D0D0D0",
              opacity: 1,
            }}
          >
            Período: {period}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  )
}

export { CardIndicator }