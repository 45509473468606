import {
  Box,
  Button,
  Container,
  Divider,
  LinearProgress,
  Stack,
  Typography,
} from "@mui/material";
import * as React from "react";
import { Download } from "@mui/icons-material";
import { DiagnosticStepsButton } from "../components/diagnosticStepsButton";
import { IndicatorPanelDiagnostics } from "../components/indicatorPanelDiagnostics";
import { EvidenceSolutions } from "../components/evidenceSolutions";

import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { useHttp } from "../hooks/useHttp";

import { SearchTextField } from "../components/searchTextField";

const Diagnostic = () => {
  const [step, setStep] = React.useState<number>(0);

  const [locale, setLocale] = useState({} as any);
  console.log({locale});
  const params = useParams();
  const { request, loading } = useHttp();
  const navigate = useNavigate();

  const getIbgeCode = React.useCallback(
    async (ibge: string) => {
      await request(
        {
          method: "GET",
          url: `/locations/${ibge}/`,
        },
        {
          onCallback: (response: any) => {
            setLocale(response.data);
          },
        }
      );
    },
    [request]
  );

  useEffect(() => {
    if (params?.ibge) {
      getIbgeCode(params.ibge);
    }
  }, [getIbgeCode, params]);

  return (
    <Box mb={6}>
      <Box
        pt="33px"
        pb="33px"
        sx={{
          background: "#F8F8F8 0% 0% no-repeat padding-box",
          opacity: 1,
        }}
      >
        <Container maxWidth="lg">
          <Stack gap={3}>
            <Stack gap={3}>
              <Typography
                sx={{
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "left",
                    lg: "left",
                    xl: "left",
                  },
                  font: "normal normal bold 38px/52px Open Sans",
                  letterSpacing: "0px",
                  color: "#242424",
                  opacity: 1,
                }}
              >
                Diagnóstico
              </Typography>
              <Typography
                sx={{
                  textAlign: {
                    xs: "center",
                    sm: "center",
                    md: "left",
                    lg: "left",
                    xl: "left",
                  },
                  font: {
                    xs: "normal normal normal 15px/20px Open Sans",
                    sm: "normal normal normal 15px/20px Open Sans",
                    md: "normal normal normal 15px/20px Open Sans",
                    lg: "normal normal normal 15px/20px Open Sans",
                    xl: "normal normal normal 15px/20px Open Sans",
                  },
                  letterSpacing: "0px",
                  color: "#6D6E71",
                  opacity: 1,
                }}
              >
                Comece seu diagnóstico pesquisando por uma cidade ou estado
              </Typography>
              <Box
                sx={{
                  width: "100%",
                }}
              >
                <SearchTextField
                  onClick={(l: any) => {
                    if (l?.ibge_code) {
                      navigate(`/diagnostico/${l?.ibge_code}`);
                    }
                  }}
                />
              </Box>
              <Divider />
            </Stack>

            {!loading && (
              <Stack gap={3}>
                <Typography
                  sx={{
                    Minwidth: "448px",
                    height: "88px",
                    textAlign: {
                      xs: "center",
                      sm: "center",
                      md: "left",
                      lg: "left",
                      xl: "left",
                    },
                    font: {
                      xs: "normal normal bold 40px/44px Open Sans",
                      sm: "normal normal bold 46px/48px Open Sans",
                      md: "normal normal bold 48px/49px Open Sans",
                      lg: "normal normal bold 48px/49px Open Sans",
                      xl: "normal normal bold 65px/88px Open Sans",
                    },
                    letterSpacing: "0px",
                    color: "#00A9EE",
                    opacity: 1,
                  }}
                >
                  {locale.name ? locale?.name : ""}
                </Typography>
                <Divider />
                <Stack
                  gap={3}
                  sx={{
                    flexDirection: {
                      xs: "column-reverse",
                      sm: "column-reverse",
                      md: "row",
                      lg: "row",
                      xl: "row",
                    },
                    alignItems: {
                      xs: "center",
                      sm: "center",
                      md: "center",
                      lg: "center",
                      xl: "center",
                    },
                    justifyContent: {
                      xs: "center",
                      sm: "center",
                      md: "space-between",
                      lg: "space-between",
                      xl: "space-between",
                    }
                  }}
                >
                  <DiagnosticStepsButton
                    activeStep={step}
                    setActiveStep={(index: number) => setStep(index)}
                  />

                  <Button
                    variant="contained"
                    startIcon={<Download />}
                    onClick={() => window.open(`/relatorio-executivo/${locale?.id}`, "_blank")}
                    sx={{
                      width: "267px",
                      height: "42px",
                      background: "#004E70 0% 0% no-repeat padding-box",
                      opacity: 1,
                      textAlign: "left",
                      font: {
                        xs: "normal normal bold 10px/15px Open Sans",
                        sm: "normal normal bold 13px/18px Open Sans",
                        md: "normal normal bold 13px/18px Open Sans",
                        lg: "normal normal bold 13px/18px Open Sans",
                        xl: "normal normal bold 13px/18px Open Sans",
                      },
                      letterSpacing: "0px",
                      color: "#FFFFFF",
                      textTransform: "none",
                      "&:hover": {
                        background: "#AAB9BF 0% 0% no-repeat padding-box",
                      },
                    }}
                  >
                    Download do Relatório Executivo
                  </Button>
                </Stack>
              </Stack>
            )}
          </Stack>
        </Container>
      </Box>

      <Box pt="18px" pb="18px">
        {loading && (
          <Container maxWidth="lg">
            <LinearProgress />
          </Container>
        )}
        {!loading && (
          <Container maxWidth="lg">
            {step === 0 && locale.categories && (
              <IndicatorPanelDiagnostics
                is_metropolitan_city={locale.is_metropolitan_city}
                categories={locale.categories}
                locationId={locale?.id}
                type={locale?.type}
              />
            )}
            {step === 1 && <EvidenceSolutions />}
          </Container>
        )}
      </Box>
    </Box>
  );
};

export { Diagnostic };
