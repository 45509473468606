import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import * as React from 'react'
import { useSnackbar } from 'notistack';

const http: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
})

const useHttp = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [loading, setLoading] = React.useState<boolean>(false)
  const request = React.useCallback(async (
    config: AxiosRequestConfig, { onCallback }: any) => {
    setLoading(true)
    try {
      const response: AxiosResponse<any, any> = await http(config)
      await onCallback?.(response)
    } catch (error) {
      console.log({
        error
      })
      enqueueSnackbar('Erro interno!', { variant: 'error' });
      throw error
    } finally {
      setLoading(false)
    }
  }, [enqueueSnackbar])
  return {
    loading,
    request
  }
}

export { useHttp }