import { Stack, Typography } from "@mui/material";
import * as React from "react";

interface ICard {
  icon: React.ReactNode;
  title: string;
  color: string;
  onClick?: () => void;
}

const Card: React.FC<ICard> = ({ icon, title, color, onClick }) => {
  return (
    <Stack
      flex={1}
      sx={{
        height: "172px",
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 3px 6px #00000029",
        border: `1px solid ${color}`,
        borderRadius: "20px",
        opacity: 1,
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <Stack
        flex={1}
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
        p="16px"
      >
        <Typography
          sx={{
            width: "185px",
            textAlign: "left",
            font: "normal normal bold 16px/22px Open Sans",
            letterSpacing: "0px",
            color: color,
            opacity: 1,
            maxWidth: "100px",
          }}
        >
          {title}
        </Typography>
        <Stack
          justifyContent="center"
          alignItems="center"
          sx={{
            width: "75px",
            height: "75px",
            background: `${color} 0% 0% no-repeat padding-box`,
            borderRadius: "10px",
            opacity: 1,
          }}
        >
          {icon}
        </Stack>
      </Stack>
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{
          height: "41px",
          background: `${color} 0% 0% no-repeat padding-box`,
          boxShadow: "0px 3px 6px #00000029",
          borderRadius: "0px 0px 20px 20px",
          opacity: "0.93",
          cursor: "pointer",
        }}
      >
        <Typography
          sx={{
            textAlign: "center",
            font: "normal normal bold 16px/22px Open Sans",
            letterSpacing: "0px",
            color: "#FFFFFF",
            opacity: 1,
          }}
        >
          Ver painel de Indicadores
        </Typography>
      </Stack>
    </Stack>
  );
};

export { Card };
