import styled from 'styled-components';

export const Container = styled.div`
  .info-labels {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;

    .graphicContainer {
      background: red;
    }
  }
`;

export const InfoLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;

  div {
    width: 15px;
    height: 15px;
    background: gray;
    margin-right: 5px;
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 75px;
  .legend-item {
    display: flex;
    align-items: center;
    font: normal normal normal 12px/15px IBM Plex Sans;
    letter-spacing: 0px;
    color: #333333;
    opacity: 1;
    margin-right: 15px;

    .green1 {
      margin-right: 15px;
      width: 20px;
      height: 20px;
      background: #5eb245;
      border: 1px solid #5eb245;
      opacity: 1;
    }

    .green2 {
      margin-right: 15px;
      width: 20px;
      height: 20px;
      background: #30e861;
      border: 1px solid #30e861;
      opacity: 1;
    }

    .yellow {
      width: 20px;
      margin-right: 15px;
      height: 20px;
      background: #ffc21d;
      border: 1px solid #ffc21d;
      opacity: 1;
    }

    .orange {
      width: 20px;
      margin-right: 15px;
      height: 20px;
      background: #e37100;
      border: 1px solid #e37100;
      opacity: 1;
    }

    .red {
      width: 20px;
      margin-right: 15px;
      height: 20px;
      background: #de2b2b;
      border: 1px solid #de2b2b;
      opacity: 1;
    }
  }
`;
